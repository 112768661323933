.btn-action-possystem {
  height: 40px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.text-cus-kept {
  color: red;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}
.text-12B064 {
  color: #0b2f88;
}
