.modal-enter-guest .modal-dialog {
  max-width: 806px;
  width: 806px;
}

.modal-enter-guest .modal-dialog .modal-dialog {
  max-width: 806px;
  width: 806px;
}

.box-keyboard-guest {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.item-keyboard-guest {
  border-radius: 8px;
  aspect-ratio: 1/1;
  border: 1px solid #707070;
  background-color: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 700;
  color: #4a4a6a;
  font-size: 20px;
}
