@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.name-brand-store {
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.pos-brand-store {
  font-family: SVN-Gilroy, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #fec54a;
}

.h-40px {
  height: 40px;
}

.w-40px {
  width: 40px;
}

.w-50px {
  width: 50px;
}

.rounded-12px {
  border-radius: 12px;
}

.w-24px {
  width: 24px;
}

.h-24px {
  height: 24px;
}

.bg-3460cb {
  background-color: #3460cb;
}

.custom-fixed-logout-owner {
  border-top: 1px white solid;
  cursor: pointer;
}

.custom-btn-print-download-all {
  background-color: white;
  /* border-radius: 24px, 0px, 0px, 24px; */
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 50;
}

.text-btn-print-download {
  display: none;
  /* //styleName: Heading/Heading 3; */
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
}

.custom-btn-print-download-all:hover .text-btn-print-download {
  display: block;
  color: #ff7b2c;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
}

.btn-download-print-download {
  border-radius: 8px;
  border: 2px solid #ff7b2c;
  background-color: #fff2ea;
}

.fill-FF7B2C {
  /* fill: #FF7B2C !important; */
  /* to black */
  /* filter: invert(1); */
  /* or to blue */
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);
}

.custom-fixed-owner-mode {
  border-top: 1px white solid;
  cursor: pointer;
}

.sroll-menu {
  height: 100%;
  overflow-y: scroll;
}

.tab-menu-footer {
  /* position: absolute;
  bottom: 0px;
  right: 0; */
  width: 100%;
}

.h-96px {
  height: 96px;
}

.btn-web-support {
  padding: 10px 12px;
  border-radius: 8px;
  background: white;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #0b2f88;
  font-weight: 700;
}
.bg-0fa54a {
  background-color: #0b2f88;
}

.notification-reservation {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid rgb(253, 140, 140);
}

.box-menu-sidebar {
  height: calc(100vh - 140px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bth-change-side-bar {
  height: 40px;
  width: 40px;
  position: absolute;
  right: 0;
  border-radius: 50%;
  bottom: 145px;
  background-color: white;
  z-index: 1002;
  border: 1px solid #3460cb;
  display: flex;
  justify-content: center;
  align-items: center;
}
