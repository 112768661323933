.custom-root {
    width: 100%;
    min-height: calc(100vh - 5px);
    background-color: #EFF3F1;
    /* overflow: hidden; */
}

.custom-notification {
    border-left: 3px solid;
    padding-top: 100px;
    width: 300px;
    height: 100%;
    background-color: white;
    min-height: 100%;
}

.text-notification-router {
    font-weight: bold;
    color: #4a4a6a;
    font-size: 26px;
}

.box-content-notification {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    border-top: 1px solid #929292;
}

.w-100vw {
    width: 100vw;
}

.w-100vw-190px {
    width: calc(100vw - 190px);
}
.bth-change-show-notification{
    height: 40px;
    width: 40px;
    position: absolute;
    left: -20px;
    border-radius: 50%;
    top: 130px;
    background-color: white;
    z-index: 1002;
    border: 1px solid #3460cb;
    display: flex;
    justify-content: center;
    align-items: center;
}