.bg-F3F5F7 {
  background-color: #f3f5f7;
}

.header-kitchen-counter {
  height: 76px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 48px 12px 48px;
  border-bottom: 1px solid #e8e8e8;
}

.text-home-header-counter {
  font-family: SF Pro Display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #a9a9a9;
}

.text-request-header-counter {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #0b2f88;
}

.btn-history-counter {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 52px;
  padding: 12px 24px;
  border-radius: 6px;
  background-color: #0b2f88;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.potiton-absolute-hidden-bar {
  width: 100%;
  height: 26px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -26px;
}

.custom-bar-hidden-header {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  width: 229px;
  height: 100%;
  border-radius: 0px 0px 24px 24px;
  background-color: white;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.rotate-180deg {
  rotate: 180deg;
}

.h-100-vh-header-counter {
  height: calc(100vh - 76px);
}

.name-table-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  padding: 0px 24px;
  background-color: #ff7b2c;
  border-radius: 12px;
  width: fit-content;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.time-table-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 52px;
  padding: 0px 12px;
  background-color: #e9f5ff;
  border-radius: 12px;
  width: fit-content;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #269aff;
}

.box-bill-counter {
  width: 100%;
  border-radius: 12px;
  padding: 16px;
  background-color: white;
  /* max-width: 400px; */
}

.complete-table-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 52px;
  padding: 0px 14px;
  background-color: white;
  border-radius: 12px;
  width: fit-content;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
  border: 1px solid #0b2f88;
}

.text-name-order-bill {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.name-food-counter {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-number-food-counter {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.text-x-counter {
  font-family: Mulish, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
  margin-top: 2px;
}

.box-option-food-counter {
  /* height: 28px; */
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f9f8;
  border-radius: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.box-status-eat-counter {
  /* height: 28px; */
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff2ea;
  border-radius: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
  flex-shrink: 0;
}

.text-accompanying-food {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-12B064 {
  color: #0b2f88;
}

.text-note-food-counter {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.px-48px {
  padding-left: 48px;
  padding-right: 48px;
}

.pt-48px {
  padding-top: 48px;
}

.custom-column-counter-food {
  column-count: 5;
  column-gap: 15px;
  -moz-column-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  column-break-inside: avoid;
}

.box-bill-counter {
  break-inside: avoid;
}

.mt-76px {
  margin-top: 76px;
}

.custom-position-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
}

.hr-dashed {
  border: 1px dashed #a5a5ba;
}

.text-dish-ready {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #a5a5ba;
  background-color: white;
  padding: 0px 10px;
}

.h-calc-100vh-121px {
  height: calc(100vh - 121px);
}

.custom-column-counter-food::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-column-counter-food::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 1440px) {
  .custom-column-counter-food {
    column-count: 3;
    -moz-column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
  }
}

@media only screen and (max-width: 1024px) {
  .custom-column-counter-food {
    column-count: 2;
    -moz-column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
  }
}

@media only screen and (max-width: 768px) {
  .custom-column-counter-food {
    column-count: 1;
    -moz-column-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid;
  }
}

.btn-back-kitchen-counter {
  height: 40px;
  background: #0b2f88;
  border-radius: 12px;
  padding: 0px 12px 0px 12px;
  border: none;
  color: white;
  font-size: 20px;
  font-family: 500;
  font-family: "Roboto", sans-serif;
}

.img-pickup-bill-order {
  width: 50px;
}
