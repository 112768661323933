.shadow-btn-group {
  box-shadow: 0px 3px 5px 0px #1011131a;
}

.custom-percent-report {
  position: absolute;
}

.custom-raidus-customer span.apexcharts-legend-marker {
  border-radius: 50% !important;
}

.w-266px {
  width: 266px;
}

.body-content-scroll {
  height: calc(100vh - 150px) !important;
}

.body-content-incomedetail {
  height: calc(100vh - 160px) !important;
  overflow-y: scroll;
}

.btn-email-download-report {
  width: 200px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.btn-email-download-report:nth-child(1) {
  background: #4198d7;
  border: 1px solid #4198d7;
}

.btn-email-download-report:nth-child(2) {
  background: #dc3f31;
  border: 1px solid #dc3f31;
}

.btn-email-download-report:nth-child(3) {
  background: #0b2f88;
  border: 1px solid #0b2f88;
}

.column-box-report {
  border-right: 1px solid #11141f;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.text-box-content-report {
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #11141f;
}

.box-container-report {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.box-table-report {
  border: 1px solid #11141f;
}

.column-box-report:nth-last-child() {
  border-right: none !important;
}

.icon-report-up-down {
  font-size: 12px !important;
}

.icon-report-up {
  font-size: 12px !important;
}
.text-title-table-report {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
  color: #4a4a6a;
}

.img-year-report {
  padding: 12px;
  width: 300px;
  border-radius: 12px;
}
.btn-down-img {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 30px;
  border-radius: 6px;
  background-color: #0b2f88;
  color: white;
  font-weight: 700;
  border: none;
}
.hidden-print {
  position: fixed;
  top: -1000px;
  left: -1000px;
}

.p-table-cell {
  padding: 0.85rem;
}
