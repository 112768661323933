.list-handheld-machine{
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr ; */
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  width: 100%;
}
.border-4a4a6a{
  border: solid 1px #4a4a6a;
}