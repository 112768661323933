.w-200px {
  width: 300px;
}

.btn-add-food-cart-pos-menu {
  height: 40px;
  width: 40px;
  border-radius: 0px 0px 0px 100px;
  top: 0;
  right: 0;
  background-color: #0b2f88;
  position: absolute;
}

.btn-icon-plus-pos-menu {
  position: absolute;
  top: 8px;
  left: 12px;
}

.h-183px {
  height: 183px;
}

.text-price-food-pos-menu-old {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto;
  font-size: 10px;
  height: fit-content;
  font-weight: 1000;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #666666;
  margin-right: 2px;
  /* text-decoration: line-through; */
}

.text-price-food-pos-menu {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto;
  font-size: 14px;
  height: fit-content;
  font-weight: 1000;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
  margin-right: 2px;
}

.height-140px {
  height: 140px;
}

.object-fit-cover {
  object-fit: cover;
}

.h-150px {
  height: 150px;
}

.h-60px {
  height: 58px;
  padding-top: 10px;
}

.height-60px {
  height: 60px;
}

.height-45px {
  height: 45px;
}

.h-32px {
  height: 32px;
}

.text-dollar-pos-menu-old {
  color: #ffb080;
  font-size: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 13px;
}

.text-dollar-pos-menu {
  color: #ffb080;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.text-name-food-pos-menu {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
  margin-top: 4px;
}

.h-47px {
  height: 47px;
}

.w-130px {
  width: 130px;
}

.w-110px {
  width: 110px;
}

.h-70px {
  height: 70px;
}

.h-40px {
  height: 40px;
}
