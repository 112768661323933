.modal-order-funtion .modal-content {
  border-radius: 16px;
}

.text-title-order-function {
  font-size: 32px;
  color: black;
  font-weight: 600;
  text-align: center;
}

.btn-order-function {
  width: 30%;
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  background: green;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.btn-order-function:nth-child(2) {
  background-color: rgb(255, 62, 62);
}