.container-today {
  display: flex;
  gap: 8px;
  padding: 12px;
  background-color: #f3f3f3;
  border: 1px solid #0b2f88;
  border-radius: 8px;
  color: #000;
  height: calc(100vh - 370px);
  overflow-y: scroll;
}

.font-600 {
  font-weight: 600;
}

.flex-swap {
  flex-wrap: wrap;
}

.bg-AA8300 {
  background-color: #aa8300;
}

.bg-C57E1F {
  background-color: #c57e1f;
}

.bg-EB04A3 {
  background-color: #eb04a3;
}

.bg-CB6CE6 {
  background-color: #cb6ce6;
}

.bg-004AAD {
  background-color: #004aad;
}

.bg-006D37 {
  background-color: #006d37;
}

.bg-E7191F {
  background-color: #e7191f;
}

.bg-0989FF {
  background-color: #0989ff;
}

.title-all-food {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background-color: white;
  padding: 12px 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #4a4a6a;
}

.icon-all-food {
  font-size: 14px;
  color: #4a4a6a;
}

.btn-back-feedback {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 12px;
  background-color: #0b2f88;
  color: white;
}

/* .title-all-food:hover {
    text-decoration: underline;
} */

.des-ranking-food {
  font-size: 16px;
  color: #000;
  font-family: "Roboto", sans-serif;
}

.title-show-ranking {
  font-size: 16px;
  font-weight: 700;
  color: #4a4a6a;
  font-family: "Roboto", sans-serif;
}

.fixed {
  position: fixed;
}

.bottom-3 {
  bottom: 80px;
}

.right-3 {
  right: 30px;
}

.h-30px {
  height: 30px;
}

.w-30px {
  width: 30px;
}

.h-160px {
  height: 160px;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  background-color: rgb(224, 223, 223);
  border-radius: 16px;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 4px;
  border-radius: 16px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.tabs-pos-menu {
  border-bottom: none !important;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tabs-pos-menu .nav-item {
  border-radius: 8px;
  overflow: hidden;
  height: auto;
  padding: 4px 0px;
}

.tabs-pos-menu .nav-item .nav-link {
  border-radius: 8px;
  border: 1px solid #0b2f88;
  color: #0b2f88;
  font-size: 20px;
}

.tabs-pos-menu .nav-item .nav-link:hover {
  border-radius: 8px;
  border: 1px solid rgb(166, 240, 166);
  background-color: rgb(53, 150, 53);
  color: white;
}

.tabs-pos-menu .nav-item .nav-link.active {
  background-color: #0b2f88;
  color: white;
}

.custom-menu-items {
  width: 250px;
  height: auto;
  padding: 16px 0px;
}

.custom-img-menu-items {
  overflow: hidden;
  border-radius: 16px;
  width: 200px;
  height: 100px;
}

.border-right-gray {
  border-right: 1px solid rgb(188, 188, 188);
}

.border-top-gray {
  border-top: 1px solid rgb(188, 188, 188);
}

.navbar-custom {
  top: 0;
}

.h-70px {
  height: 70px;
}

.custom-h-content-food {
  /* height: fit-content; */
  height: calc(100vh - 218px);
}

.mt-header {
  margin-top: 70px;
}

.input-search-table {
  /* //styleName: Input/Label/Placeholder; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
}

.w-40 {
  width: 40%;
}

.status-table-available {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 4px solid #c6e2ff;
  background-color: #269aff;
}

.status-table-peserved {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 4px solid #ffcccc;
  background-color: #ff7b2c;
}

.status-table-paid {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 4px solid #b4efc6;
  background-color: #0b2f88;
}

.w-333px {
  width: 333px;
}

.name-status-table {
  /* //styleName: Heading/Heading 4; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #a9a9a9;
}

.z-20 {
  z-index: 20;
}

.input-search-table {
  /* //styleName: Input/Label/Placeholder; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.input-search-table::placeholder {
  /* //styleName: Input/Label/Placeholder; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.navbar-custom {
  box-shadow: none !important;
  border-bottom: 1px solid #d7d7d7;
}

.border-bottom {
  border-bottom: 1px solid #d7d7d7;
}

.scroll-bar-style {
  scrollbar-color: #e3e3e3 transparent;
}

div {
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 4px !important;
  background-color: #dddddd;
}

::-webkit-scrollbar-track-piece:vertical {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a4a4a4;
}

::-webkit-scrollbar-track-piece:horizontal {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #a4a4a4;
}

::-webkit-scrollbar-thumb:vertical {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb:horizontal {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.h-90px {
  height: 90px;
}

.w-70px {
  width: 70px;
}

.rorate-left {
  rotate: -90deg;
}

.rorate-right {
  rotate: 90deg;
}

.btn-choose-floor {
  width: 62px;
  height: 50px;
  border-radius: 12px;
  border: 1px solid #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-floor {
  font-family: SVN-Gilroy, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.custom-header-search {
  width: calc(100%);
}

.border-modal-16px .modal-dialog .modal-content {
  border-radius: 16px;
}

.text-12px {
  font-size: 12px;
}

.mt-140px {
  margin-top: 140px;
}

.fitlter-pos-menu {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}

.select-food-pos-menu {
  height: 50px;
  width: 295px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.002em;
  text-align: left;
  color: #4a4a6a;
}

.select-food-pos-menu:focus-visible {
  border: 1px solid #dee2e6 !important;
  outline: none !important;
}

.select-food-pos-menu {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  height: 45px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 0.5rem;
}

.select-food-pos-menu::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 10px;
  position: absolute;
}

.text-choose-status-order {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.border-right {
  border-right: 1px solid #e8e8e8;
}

.pl-50px {
  padding-left: 50px;
}

.input-find-dishes {
  /* //styleName: Input/Label/Placeholder; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
  width: 300px;
  border: none;
  outline: none;
}

.page-menu-post-table {
  width: 100%;
  height: calc(100vh - 140px);
}

.height-100vh-header {
  height: calc(100vh - 70px);
}

.mb-3px {
  margin-bottom: 3pxa;
}

.tab-order-food {
  width: 600px;
  height: 100%;
  background-color: white;
  flex-shrink: 0;
}

.input-find-dishes::placeholder {
  color: #a5a5ba;
}

.rounded-12px {
  border-radius: 12px;
}

.h-50px {
  height: 50px;
}

.w-80px {
  width: 80px;
}

.bg-0B2F88 {
  background-color: #0b2f88;
}

.bg-f1735d {
  background-color: #f1735d;
}

.name-brand-pos-menu {
  /* //styleName: Heading/Heading 3; */
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.name-table-pos-menu {
  /* //styleName: Heading/Heading 4; */
  color: white;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.order-number-pos-menu {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #4a4a6a;
}

.clear-food-ordeer {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
}

.h-170px {
  height: 170px;
}

.absolute-center {
  position: absolute;
  bottom: -22px;
  height: 22px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}

.open-list-food-order {
  width: 230px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: #e9f5ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px auto;
  border-radius: 0px 0px 24px 24px;
  justify-content: center;
}

.h-16px {
  height: 16px;
}

.w-300px {
  width: 300px;
}

.w-16px {
  width: 16px;
}

.text-title-bill {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-price-title-bill {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.w-48 {
  width: 48% !important;
}

.h-56px {
  height: 56px;
}

.radius-16px {
  border-radius: 16px;
}

.tip-employee-pos-menu {
  height: 42px;
  border: 1px solid #eaeaef;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-14px {
  font-size: 14px;
}

.radius-8px {
  border-radius: 8px;
}

.border-input-bootstrap-none span {
  border: none;
}

.border-input-bootstrap-none input {
  border: none;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.border-input-bootstrap-none input:focus {
  border: none;
  color: #ff7b2c;
}

.border-input-bootstrap-none input:focus-visible {
  border: none;
}

.name-employee-tip-pos-menu {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
  max-width: 117px;
}

.border-input-bootstrap-none span {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.input-discount-pos-menu {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
  border: none;
}

.input-discount-pos-menu::placeholder {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8e8ea9;
  height: 100%;
}

.btn-order-menu-pos {
  width: 100%;
  height: 80px;
  background-color: #ff7b2c;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  margin-top: 18px;
}

.h-cacl-content-pos-menu {
  height: calc(100vh - 700px);
  overflow-y: scroll;
}

.h-cacl-content-pos-menu-323 {
  height: calc(100vh - 671px);
  overflow-y: scroll;
}

.h-order-eat-here {
  height: calc(100vh - 385px);
  overflow-y: scroll;
}

.rorate-180 {
  rotate: 180deg;
}

.custom-text-view-more {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px auto;
  border-radius: 0px 0px 24px 24px;
  justify-content: center;
  color: #228be6;
}

.text-choose-employee {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #b2b5bd;
}

.w-180px {
  width: 180px;
}

.border-select-none.react-select > div {
  border: none !important;
}

.border-right {
  border-right: 1px solid #dedede;
}

.input-find-by-phone-employee {
  width: 400px;
  border: none;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.input-find-by-phone-employee::placeholder {
  color: #a5a5ba;
}

.text-customer-receipts {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-title-table {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.header-table-customer {
  height: 72px;
}

.bg-F6F6F9 {
  background-color: #f6f6f9;
}

.w-10 {
  width: 8%;
}

.w-16 {
  width: 14.6%;
}

.w-2 {
  width: 4%;
}

.mb-2px {
  margin-bottom: 2px;
}

.text-content-table-customer {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.text-content-table-customer-3 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.text-32324D {
  color: #32324d;
}

.text-12B064 {
  color: #0b2f88;
}

.text-4A4A6A {
  color: #4a4a6a;
}

.text-269AFF {
  color: #269aff;
}

.text-dollar-table-customer {
  font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.text-8E8EA9 {
  color: #8e8ea9;
}

.text-71D0A2 {
  color: #71d0a2;
}

.text-32324D {
  color: #32324d;
}

.float-left {
  float: left;
}

/* .custom-h-body-page-customer {
    display: block;
    box-sizing: unset;
    overflow: hidden;
    height: calc(100vh - 182px) !important;
} */

/* .table-container {
    max-height: 100%;
    overflow-y: auto;
} */
tbody.customer-receipts {
  display: table-caption;
  height: calc(100vh - 300px) !important;
  overflow-y: scroll;
}

tbody.offline-mode {
  display: table-caption;
  height: calc(100vh - 245px) !important;
  overflow-y: scroll;
}

tbody.clock-in-clock-out {
  display: table-caption;
  height: calc(100vh - 340px) !important;
  overflow-y: scroll;
}

.h-66px {
  height: 66px;
}

.w-17 {
  width: 17%;
}

.w-12 {
  width: 12%;
}

.w-42 {
  width: 42%;
}

.btn-page-customer-receipts {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 8px;
  background-color: white;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #4a4a6a;
}

.btn-page-customer-receipts-target {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 8px;
  background-color: #e7f7ef;
  color: #0b2f88;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}

.text-page-table-bottom {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.input-text-page {
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #dcdce4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-none:focus-visible,
.border-none:focus {
  border: none;
  outline: none;
}

.rounded-8px {
  border-radius: 8px;
}

.daterangepicker td.in-range {
  background-color: #357ebd !important;
  color: white;
}

.w-195px {
  width: 195px;
}

.h-42px {
  height: 42px;
}

.h-88px {
  height: 88px;
}

.text-check-in-clock {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}

.pt-header {
  padding-top: 70px;
}

.drop-shadow-btn-check {
  box-shadow: 0px 4px 120px 0px rgba(36, 36, 36, 0.08);
}

.custom-h-body-page-clock-in-out {
  height: 100vh;
}

.border-y {
  border-top: 1px solid #dcdce4;
  border-bottom: 1px solid #dcdce4;
}

.custom-root {
  overflow: hidden;
}

.print-ticket {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #909296;
}

.menu-setup-table {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  gap: 18px;
  border-radius: 12px;
}

.bottom-40px {
  bottom: 40px;
}

.w-box-menu-table {
  width: calc(100% - 237px);
}

.text-manage-setup-table {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-tool-setup-table {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #a5a5ba;
}

.box-btn-function-setup-table {
  padding: 0px 32px;
  height: 58px;
  display: flex;
  align-items: center;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
}

.text-function-setup-table {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-title-tab-setting {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.custom-switch-radio > input.form-check-input {
  height: 24px !important;
  width: 42px !important;
}

.text-value-input-message {
  height: 128px;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #101113;
}

.custom-position-header-tab {
  position: fixed;
  top: 70px;
}

.custom-btn-save-setting-information {
  margin-top: 6px;
  border-radius: 8px;
  border: none;
  background: #0b2f88;
  color: white;
  padding: 2px 15px;
  font-weight: 600;
  line-height: 28px;
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 99;
}

.box-input-enter-time {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.input-enter-time {
  flex-shrink: 0;
  width: 40px;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #2c2e33;
}

.input-enter-time::placeholder {
  flex-shrink: 0;
  width: 40px;
  font-family: Roboto, sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #a6a7ab;
  background-color: transparent;
}

.radio-setting-information > input {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.radio-setting-information > label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.002em;
  text-align: left;
  color: #101113;
}

.box-input-enter-point {
  width: 50px;
  height: 41px;
  flex-shrink: 0;
  border: 1px solid #0b2f88;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0px 10px;
  overflow: hidden;
}

.input-enter-point,
.input-enter-point::placeholder {
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.005em;
  text-align: center;
  width: 100%;
  color: #0b2f88;
  background-color: transparent;
}

.box-input-enter-time-2 {
  width: 60px;
  height: 41px;
  flex-shrink: 0;
  border: 1px solid #dee2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.input-enter-time-2,
.input-enter-time-2::placeholder {
  flex-shrink: 0;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: -0.005em;
  text-align: center;
  width: 100%;
  color: #a6a7ab;
  background-color: transparent;
}

.input-enter-time-2 {
  color: #2c2e33 !important;
}

.box-select-vourcher {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  background-color: white;
}

.text-select-voucher {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  background-color: transparent;
}

.box-select-vourcher .react-select__control.css-yk16xz-control,
.box-select-vourcher .react-select__control.css-yk16xz-control:focus,
.box-select-vourcher .react-select__control.css-yk16xz-control:focus-visible {
  background-color: transparent !important;
}

.box-select-vourcher
  .react-select__control
  .react-select__value-container
  .css-1wa3eu0-placeholder {
  color: #212134;
}

.pb-100px {
  padding-bottom: 100px;
}

.width-160px {
  width: 160px !important;
}

.box-select-month {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #0b2f88;
  display: flex;
  align-items: center;
  background-color: white;
}

.text-select-month {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.002em;
  text-align: left;
  background-color: transparent;
}

.box-select-month
  .react-select__control
  .react-select__value-container
  .css-1wa3eu0-placeholder {
  color: #0b2f88;
}

.w-60px {
  width: 60px !important;
}

.w-40px {
  width: 40px !important;
}

.custom-btn-close {
  position: absolute;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(241, 62, 62);
  top: -20px;
  right: -20px;
  border-radius: 50%;
}

.border-orange {
  border: 1px solid #ff7b2c !important;
}

.border-orange-2 {
  border: 4px solid #ff7b2c !important;
}

.border-left {
  border-left: 1px solid #eaeaef !important;
}

.table-multi-select {
  width: 92px;
  height: 60px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: SF Pro Display, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.btn-close-multi-select {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #ff521c;
  left: 73px;
  top: -12px;
  position: absolute;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

button.shadow-btn-group:focus:not(:focus-visible) {
  box-shadow: 0px 3px 5px 0px #1011131a !important;
}

.btn-add-categories {
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #0b2f88;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  border: none;
  outline: none;
}

.text-menu-management {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #4a4a6a;
}

.rounded-18px {
  border-radius: 18px;
}

.bg-tranparent {
  background-color: transparent !important;
}

.pb-107px {
  padding-bottom: 107px;
}

.number-select-month {
  width: 120px !important;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.z-20 {
  z-index: 20;
}

.text-FAA300 {
  color: #faa300;
}

.bg-FFF7E8 {
  background-color: #fff7e8;
}

.super-app-theme--header {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.text-table {
  /* //styleName: Body/Body 1; */
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.height-table-inventory {
  height: calc(100vh - 274px);
}

.text-add-new-payment {
  padding-top: 20px;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-add-new-payment-content {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.box-connect-stripe {
  margin-top: 57px !important;
  background-color: #eff3f1;
  height: calc(100vh - 128px) !important;
}

.bg-EFF3F1 {
  background-color: #eff3f1;
}

.btn-add-payment-gateway {
  width: 240px;
  height: 60px;
  border-radius: 16px;
  background-color: #0b2f88;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.account-payment {
  display: flex;
  gap: 16px;
  align-items: center;
}

.name-account-payment {
  color: #0b2f88;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.name-account-connected {
  color: #005f9f;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.text-005f9f {
  color: #005f9f;
}

.box-option-account-payment {
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: #999999;
  background-color: rgb(253, 96, 96);
  color: white;
}

.box-option-account-payment-2 {
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: #999999;
  background-color: rgb(39, 198, 63);
  color: white;
}

.w-400px {
  width: 400px;
}

.btn-add-account-payment-gateway {
  padding: 8px 26px;
  border-radius: 8px;
  background-color: #0b2f88;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.box-input-account-payment {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
}

.btn-back-connect-stripe {
  display: flex;
  gap: 2px;
  padding-top: 20px;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  background-color: #0b2f88;
  width: fit-content;
  padding: 10px 16px;
  color: white;
  border-radius: 12px;
  margin-bottom: 20px;
}

.text-dollar-staff {
  height: fit-content;
  font-size: 20px;
  font-weight: 600;
  font-family: unset;
}

.calc-100vh-482px {
  height: calc(100vh - 420px);
}

.btn-edit-delete-inventory-items {
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid rgb(249, 61, 61);
  font-family: Roboto, sans-serif;
  display: flex;
  gap: 4px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  width: fit-content;
  color: rgb(249, 61, 61);
  border-radius: 12px;
}

.btn-submit-offline {
  display: flex;
  gap: 2px;
  padding-top: 20px;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  background-color: #0b2f88;
  width: fit-content;
  padding: 10px 16px;
  color: white;
  border-radius: 12px;
}

.w-100-190px {
  width: calc(100vw - 190px);
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.note-setting-this-sentence {
  text-decoration: underline;
  font-weight: 700;
  color: black;
}

.fs-12 {
  font-size: 12px !important;
}

.pl-70px {
  padding-left: 70px;
}

.h-100vh-374px {
  height: calc(100vh - 374px);
}

.h-100vh-450px {
  height: calc(100vh - 500px);
}

.text-successs-rv {
  font-size: 28px;
  font-weight: 700;
}

.font-weight-normal {
  font-weight: normal !important;
}

.text-stripe-total {
  font-weight: 600;
  font-size: 28px;
  font-family: "roboto", sans-serif;
  color: rgb(22, 165, 197);
}

.text-stripe-total-price {
  font-weight: 600;
  font-size: 28px;
  font-family: "roboto", sans-serif;
  color: rgb(8, 155, 37);
}

.box-back-reader {
  width: 100%;
  border-radius: 40px;
  border: 1px solid rgb(22, 165, 197);
  font-weight: 600;
  font-size: 28px;
  font-family: "roboto", sans-serif;
  color: rgb(22, 165, 197);
}

.overflow-y-scroll {
  scrollbar-width: thin;
  scrollbar-color: #e3e3e3 transparent;
}

.overflow-x-scroll {
  scrollbar-width: thin;
  scrollbar-color: #e3e3e3 transparent;
}

div {
  scrollbar-color: #e3e3e3 transparent;
}

body {
  scrollbar-color: #e3e3e3 transparent;
}

.box-icon-printer {
  border-radius: 16px;
  border: 1px solid green;
  width: fit-content;
  background: white;
}

.box-icon-add-printer {
  height: 70px;
  width: 70px;
  border-radius: 16px;
  border: 1px solid green;
  width: fit-content;
  background: white;
}

.icon-printer {
  font-size: 70px !important;
}

.icon-add-printer {
  font-size: 80px !important;
}

.text-30px {
  font-size: 30px !important;
}

.delete-printer-added {
  height: 30px;
  width: 30px;
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.box-option-choose-menu {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  /* flex-wrap: wrap; */
  /* position: absolute; */
  top: 0;
  left: 0;
  z-index: 30;
  padding: 16px;
}

.text-16px {
  font-size: 16px;
}

.h-box-content-setup-table {
  min-height: calc(100vh - 200px);
}

.h-body-qr-code {
  height: calc(100vh - 70px);
  overflow-y: scroll;
}

.h-content-password-setting {
  height: calc(100vh - 150px);
  margin-top: 20px;
  overflow-y: scroll;
}

.h-body-password-code {
  height: calc(100vh - 70px);
  margin-top: 50px;
}

.h-body-connect-printer {
  height: calc(100vh - 120px);
  overflow-y: scroll;
  margin-top: 55px;
  padding-top: 40px;
}

.box-set-up-table {
  height: calc(100vh - 244px);
  width: 100%;
  overflow: auto;
}

.min-height-menu-table {
  height: calc(100vh - 150px);
  overflow: scroll;
  padding-bottom: 20px;
}

.box-menu-template {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 270px);
  width: 100%;
}

.box-menu-template-table {
  height: calc(100vh - 270px);
  width: 100%;
  overflow: hidden;
}

.save-icon-setup-table {
  font-size: 18px;
  color: #4a4a6a;
}

.btn-save-table {
  font-weight: 700;
  color: white;
  background: #0b2f88;
  height: 58px;
  width: 96px;
  border-radius: 12px;
  border: #0b2f88;
  font-size: 18px;
}

.fous-visiable-none:focus-visible {
  outline: none;
}

.box-select-food-happy-hour {
  border-radius: 8px;
  border: 1px solid #c2c2c2;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: white;
  width: 100%;
}

.text-20px {
  font-size: 20px;
}

.check-box-start .MuiFormControlLabel-root {
  align-items: flex-start !important;
  gap: 10px;
}

.check-box-start {
  max-height: 500px;
  overflow-y: scroll;
}

.box-happy-hour {
  height: calc(100vh - 146px);
  overflow-y: scroll;
  padding-bottom: 30px;
}

.tab-menu-management .nav-item .cursor-pointer.ms-0.nav-link {
  font-weight: 700;
  border-radius: 12px;
  font-size: 18px;
}

.tab-menu-management .nav-item .cursor-pointer.ms-0.nav-link.active {
  color: white !important;
  font-weight: 700;
  background: #0b2f88;
  border-radius: 12px;
  border: #0b2f88;
  font-size: 18px;
}

.bg-gradient-go-to-owner {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgba(101, 0, 94, 1) 3%,
    rgba(60, 132, 206, 1) 38%,
    rgba(48, 238, 226, 1) 68%,
    rgb(36, 255, 25) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.wave {
  z-index: 20;
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}

/* .box-not-found-owner {
    width: 40vw;
    height: 500px;
    background: white;
    border-radius: 16px;
    margin: 0 auto;
} */
.box-img-develivery {
  height: 50px;
  width: 50px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-apply-discount-posmenu {
  padding: 4px 12px;
  border-radius: 6px;
  background: #ff7b2c;
  color: white;
  font-weight: 600;
}

.box-reservation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.text-lg {
  font-size: 18px !important;
}

.text-base {
  font-size: 16px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-three-line-note {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.d-inline {
  display: inline;
}

.h-33px {
  height: 33px;
}

.border-danger {
  border: 2px inset rgb(252, 90, 90);
}

.box-enter-total-reservation {
  position: fixed;
  top: 16px;
  right: 340px;
  display: flex;
  align-items: center;
  z-index: 99999;
  gap: 10px;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999;
}

.modal-infor-device-order {
  z-index: 999999;
}

.modal-loading-backdrop {
  z-index: 999999;
}

.box-img-cart-empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-food-upsale {
  border-radius: 16px;
  width: 100px;
  padding: 8px;
  height: 165px;
}

.img-food-upsale {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  margin-top: 4px;
}

.h-100vh-145px {
  height: calc(100vh - 142px) !important;
  overflow-y: scroll;
}

.w-18 {
  width: 18%;
}

.box-list-gift-card-detail {
  height: calc(100vh - 230px);
  overflow-y: auto;
}

.menu-physical-item {
  border: 1px solid #0b2f88;
  border-radius: 12px;
}

.text-orange-i {
  color: #ff7b2c !important;
}

.name-food-physical {
  font-weight: 700;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: normal;
  flex-shrink: 0;
}

.max-w-80 {
  max-width: 80%;
}

.doc-physical-menu {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: normal;
}

.name-store-physical-menu {
  font-weight: 700;
  color: white;
  font-family: "Madimi One", sans-serif !important;
  font-size: 34px;
  margin: 0;
  line-height: normal;
  text-align: center;
}

.thanks-physical-menu {
  font-weight: 700;
  color: white;
  font-family: "Madimi One", sans-serif !important;
  font-size: 28px;
  margin: 0;
  line-height: normal;
  text-align: center;
}

.box-food-menu-physical {
  height: 690px;
  overflow: hidden;
}

.group-name-physical {
  font-weight: 700;
  color: #4a4a6a;
  font-family: "Roboto", sans-serif !important;
  font-size: 28px;
  margin: 0px;
  line-height: normal;
}

.group-physical-menu {
  background-color: #000;
  width: fit-content;
  max-width: 60%;
  /* height: 30px; */
  padding: 10px 12px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  color: #ff521c;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: normal;
  border-radius: 12px;
}

.decreption-menu {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.price-food-name-menu-physical {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
}

.table-food-rank {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.btn-payment-method-menu {
  width: 100%;
  display: flex;
  height: 60px;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  border: 1px solid #999999;
  color: #6c657d;
}

.btn-payment-method-menu-2 {
  width: 100%;
  display: flex;
  height: 60px;
  background: #ff7b2c;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  border: 1px solid #ff7b2c;
  color: white;
}

.btn-payment-delivery {
  width: 100%;
  display: flex;
  height: 40px;
  background: #f0ddd2;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  border: 1px solid #ff7b2c;
  color: white;
  padding: 8px 0px;
}

.w-80 {
  width: 80%;
}

.max-w-100 {
  max-width: 100% !important;
}

.box-food-menu-physical {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.option-email-marketing {
  height: 40px;
  padding: 0px 20px;
  border-radius: 8px;
  border: 1px solid #999999;
  background-color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #4a4a6a;
}

.option-email-marketing-2 {
  height: 40px;
  padding: 0px 20px;
  border-radius: 8px;
  border: 1px solid #0b2f88;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: white;
  background-color: #0b2f88;
}

.find-email-marketing {
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #999999;
  width: fit-content;
  padding: 0px 12px;
  gap: 8px;
}

.input-find-email {
  background-color: transparent;
  border: none;
  font-size: 14px !important;
  color: #4a4a6a !important;
  width: 300px;
  font-weight: 500 !important;
}

.input-find-email:focus-visible {
  outline: none;
}

.import-email-marketing {
  height: 40px;
  border-radius: 8px;
  background-color: #0b2f88;
  border: 1px solid #0b2f88;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: bold;
  color: white;
}

.border-default-floor {
  border: 3px solid #3a3a3a !important;
}

.round-8px {
  border-radius: 8px;
}

.gift-card-balance-header {
  padding: 12px;
  height: 120px;
  background-color: #0b2f88;
}

.gift-card-balance-content {
  height: calc(100% - 220px);
  overflow-y: auto;
  padding: 12px !important;
}

.gift-card-balance-footer {
  padding: 12px;
  height: 100px;
  background-color: #0b2f88;
}

.content-balance-gift {
  flex-flow: 1;
  flex: 1;
}

.btn-show-img {
  height: 40px;
  border: 2px solid #999999;
  border-radius: 8px;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #4a4a6a;
  font-size: 18px;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-user-select: none;
  /* Chrome, Safari, Opera */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.btn-hidden-img {
  border: none;
  height: 40px;
  border-radius: 8px;
  padding: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  font-size: 18px;
  background-color: #ff7b2c;
  cursor: pointer;
  -moz-user-select: none !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-user-select: none;
  /* Chrome, Safari, Opera */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.btn-food-section {
  height: 32px;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: #ff7b2c;
  border: 2px solid #fa640d;
  font-weight: bold;
  color: white;
}

.btn-back-food-section {
  height: 32px;
  padding: 0px 12px;
  border-radius: 8px;
  background-color: #ff7b2c;
  border: 2px solid #fa640d;
  font-weight: bold;
  color: white;
  align-items: center;
  display: flex;
  gap: 8px;
}

.w-12 {
  width: 12%;
}

.m-w-200px {
  min-width: 200px;
}

.checkbox-handle-pos input {
  height: 20px !important;
  width: 20px !important;
}
