.modal.left .modal-dialog,
.modal.add-new-food .modal-dialog {
  position: fixed;
  margin: auto;
  width: 553px;
  max-width: none;
  height: 100vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.add-new-food .modal-content {
  height: 950vh;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.add-new-food .modal-body {
  padding: 0;
}

/*Right*/
.modal.add-new-food.fade .modal-dialog {
  right: -553px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.add-new-food.fade.show .modal-dialog {
  right: 0;
}

.modal.left .modal-dialog,
.modal.add-raw-materials .modal-dialog {
  position: fixed;
  margin: auto;
  width: 553px;
  max-width: none;
  height: 600px;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  right: 0;
  box-shadow: 0px 4px 36px 0px #24242414;
}

.modal.left .modal-content,
.modal.add-raw-materials .modal-content {
  height: 600px;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.add-raw-materials .modal-body {
  padding: 0;
}

/*Right*/
.modal.add-raw-materials.fade .modal-dialog {
  bottom: -600px;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.add-raw-materials.fade.show .modal-dialog {
  bottom: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.create-new-food {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #4a4a6a;
}

.text-detail-title {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #4a4a6a;
}

.px-68px {
  padding-left: 68px;
  padding-right: 68px;
}

.pt-48px {
  padding-top: 48px;
}

.box-upload-image {
  height: 100%;
  border: 1px dashed #0b2f88;
  padding: 12px 16px;
  border-radius: 12px;
  height: fit-content;
}

.upload-food-image {
  /* //styleName: Heading/Heading 4; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-content-upload-image {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-12B064 {
  color: #0b2f88;
}

.text-more-request::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.text-more-request {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-select-material {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-A5A5BA {
  color: #a5a5ba;
}

.text-choose-raw {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.box-choose-raw {
  padding: 12px 0px 12px 24px;
  background-color: #f6f6f9;
  border-radius: 8px;
}

.items-choose-raw {
  padding: 12px 32px;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-name-choose-raw {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-mass-choose-raw {
  color: #a5a5ba;
}

.btn-remove-box-raw {
  height: 24px;
  width: 24px;
  background-color: #ff521c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -12px;
  top: -12px;
}

.text-choose-number-side {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-input-add-something {
  /* //styleName: Body/Body 1; */
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-input-add-something::placeholder {
  color: #a5a5ba;
}

.pb-120px {
  padding-bottom: 120px;
}

.box-btn-add-new-food {
  background-color: white;
  height: 100px;
  width: 100%;
  border-top: 1px solid #d0d0d1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.w-30px {
  width: 30px;
}

.w-64px {
  width: 64px;
}

.input-qlt-raw {
  /* //styleName: Price/Input/Label/Placeholder; */
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.input-qlt-raw::placeholder {
  color: #a5a5ba;
}

.border-green {
  border: 1px solid #0b2f88;
}

.px-48px {
  padding-left: 48px;
  padding-right: 48px;
}

.position-leaf1 {
  position: absolute;
  top: 0;
  right: 0;
}

.text-label-checkbox-raw {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
  display: flex;
  align-items: center;
}

.text-label-checkbox-raw .form-check-input {
  height: 32px !important;
  width: 32px !important;
}

.h-calc-footer-raw {
  height: calc(600px - 262px) !important;
}

.w-80px {
  width: 80px;
}
