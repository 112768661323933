.food-note-item {
  padding: 4px 12px;
  border-radius: 6px;
  border: 1px solid #999999;
  color: #4a4a6a;
  background-color: white;
  font-weight: medium;
  font-size: 12px;
  width: fit-content;
  line-height: none;
  position: relative;
}
.food-note-item .icon-remove-note {
  position: absolute;
  right: -10px;
  top: -10px;
  color: red !important;
}
