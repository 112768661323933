@import url("https://fonts.googleapis.com/css2?family=Chewy&family=Jua&display=swap");

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.btn-table {
  background: #0b2f88;
  border-radius: 16px;
  padding: 4px 8px;
}

.text-number-table {
  /* //styleName: Heading/Heading 3; */
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

.w-370px {
  width: 370px;
}

.btn-download-print {
  border-radius: 8px;
  border: 1px solid #dcdce4;
  /* //styleName: Button/Medium; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.scan-to-view {
  /* //styleName: Heading/Heading 2; */
  font-family: "Jua", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #212134;
  width: 300px;
}

.operate-by {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #a5a5ba;
}

.briJack-checkin-menu {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #212134;
}

.text-table-qr {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.text-table-number-qr {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  white-space: nowrap;
}

.bg-FF7B2C {
  background-color: #ff7b2c;
}

.rounded-12px {
  border-radius: 12px;
}

.px-24px {
  padding-left: 24px;
  padding-right: 24px;
}

.bg-0B2F88 {
  background-color: #0b2f88;
}

.text-brand-name-qr {
  /* //styleName: Heading/Heading 3; */
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: white;
}

.by-using-your-phone {
  /* //styleName: Subtitle/Subtitle 2; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  color: #8e8ea9;
}

.brijack-POS {
  /* //styleName: Heading/Heading 5; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: right;
  color: #a5a5ba;
}

.pr-35px {
  padding-right: 35px;
}

.custom-hidden {
  opacity: 0;
  visibility: hidden;
}

@media print {
  body {
    print-color-adjust: exact;
  }

  .center-qr-code {
    display: flex;
    justify-content: center;
  }
}
