.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10px {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.mt-16 {
  margin-top: 64px !important;
}

.mt-38px {
  margin-top: 38px !important;
}

.mt-10 {
  margin-top: 40px;
}

.mt-11 {
  margin-top: 44px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-13 {
  margin-top: 52px;
}

.mt-14 {
  margin-top: 56px;
}

.mt-15 {
  margin-top: 60px;
}

.mt-16 {
  margin-top: 64px;
}

.mt-17 {
  margin-top: 68px;
}

.mt-18 {
  margin-top: 72px;
}

.mt-19 {
  margin-top: 76px;
}

.mt-20 {
  margin-top: 82px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-46px {
  margin-left: 46px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.ml-22 {
  margin-left: 88px !important;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-18px {
  margin-right: 18px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.mr-18 {
  margin-right: 72px;
}

.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-4 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-6 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-12 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.mx-15 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-18px {
  margin-top: 18px;
  margin-bottom: 18px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-18px {
  margin-bottom: 18px !important;
}

.m-38px {
  margin: 38px;
}

.mr-b-6 {
  margin-right: 24px !important;
  margin-bottom: 24px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.px-10 {
  padding: 0px 40px !important;
}

.px-12 {
  padding: 0px 48px !important;
}

.px-14 {
  padding: 0px 70px !important;
}

.px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.p-btn-print-sumary {
  padding: 12px 36px !important;
}

.p-6 {
  padding: 24px !important;
}

.p-15 {
  padding: 76px 60px !important;
}

.p-overal {
  padding: 7px 12px !important;
}

.p-review-inf {
  padding: 4px 8px !important;
}

.p-send-sms {
  padding: 7px 12px !important;
}

.p-review {
  padding: 10px 12px !important;
}

.p-tip {
  padding: 11px 12px;
}

.p-btn-disconnet {
  padding: 8px 24px !important;
}

.p-btn-print {
  padding: 18px 24px 24px 24px !important;
}

.custom-p-3 {
  padding: 12px 34px 12px 12px !important;
}

.custom-content-Tag {
  padding: 10px 92px;
}

.custom-p-password {
  padding: 10px 70px;
}

.custom-cancel {
  position: absolute;
  top: -10px;
  right: 0;
}

.p-option {
  padding: 12px 32px 12px 32px !important;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.p-3 {
  padding: 12px !important;
}

.p-4 {
  padding: 16px !important;
}

.p-5 {
  padding: 20px !important;
}

.p-6 {
  padding: 24px !important;
}

.p-11 {
  padding: 44px !important;
}

.p-10px {
  padding: 10px !important;
}

.py-12px {
  padding: 12px 0px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding: 20px 0px !important;
}

.py-6 {
  padding: 24px 0px !important;
}

.py-8 {
  padding: 32px 0px !important;
}

.py-11 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.px-7 {
  padding: 0px 28px;
}

.pt-26px {
  padding-top: 26px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-7 {
  padding-top: 24px !important;
}

.pt-6 {
  padding-top: 30px !important;
}

.pt-8 {
  padding-top: 36px !important;
}

.pt-9 {
  padding-top: 40px !important;
}

.pt-10 {
  padding-top: 44px !important;
}

.pt-11 {
  padding-top: 48px !important;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.pl-13 {
  padding-left: 52px !important;
}

.pl-14 {
  padding-left: 56px !important;
}

.pl-15 {
  padding-left: 60px !important;
}

.pl-16 {
  padding-left: 64px !important;
}

.pl-17 {
  padding-left: 68px !important;
}

.pl-18 {
  padding-left: 72px !important;
}

.pl-19 {
  padding-left: 76px !important;
}

.pl-20 {
  padding-left: 80px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 32px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pb-19 {
  padding-bottom: 72px !important;
}

.p-plus {
  padding: 10px 20px !important;
}

.p-t-l {
  padding: 10px 0px 10px 10px;
}

.p-l-r-b {
  padding: 0px 16px 16px 16px;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.pr-12 {
  padding-right: 48px !important;
}

.pr-14px {
  padding-right: 14px !important;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-10 {
  bottom: 10px !important;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.bg-transparent {
  background: transparent !important;
}

.bg-00a15a {
  background: #0b2f88 !important;
}

.bg-FF7B2C {
  background-color: #ff7b2c;
}

.bg-ffffff {
  background: #ffffff;
}

.bg-FFF2EA {
  background: #fff2ea;
}

.bg-F6F9F8 {
  background: #f6f9f8;
}

.bg-B4EFC640 {
  background: #b4efc640;
}

.bg-EFF3F1 {
  background: #eff3f1 !important;
}

.bg-12B0641A {
  background: #0b2f881a !important;
}

.bg-FFEEEE {
  background: #ffeeee;
}

.bg-DCDCE4 {
  background: #dcdce4;
}

.bg-EDFEF0 {
  background: #edfef0;
}

.bg-F6F6F9 {
  background: #f6f6f9 !important;
}

.bg-E9F5FF {
  background-color: #e9f5ff;
}

.bg-B4EFC6 {
  background-color: #b4efc6;
}

.bg-0FA54A {
  background-color: #0b2f88;
}

.bg-white {
  background: white;
}

.bg-F8F9FA {
  background: #f8f9fa;
}

.h-body-table {
  height: calc(100vh - 300px) !important;
  display: table-caption;
  overflow-y: scroll;
}
.h-body-table-report {
  height: calc(100vh - 550px) !important;
  display: table-caption;
  overflow-y: scroll;
}

.h-body-table-customer {
  height: calc(100vh - 330px) !important;
}

.h-100vh {
  height: 100vh !important;
}

.h-20px {
  height: 20px !important;
}

.h-40px {
  height: 40px;
}

.h-48px {
  height: 48px !important;
}

.h-280px {
  height: 280px !important;
}

.h-50px {
  height: 50px !important;
}

.h-100px {
  height: 100px !important;
}

.h-390px {
  height: 390px !important;
}

.h-430px {
  height: 430px !important;
}

.h-450px {
  height: 450px !important;
}

.h-500px {
  height: 500px !important;
}

.h-535px {
  height: 535px !important;
}

.h-600px {
  height: 600px !important;
}

.h-fit-content {
  height: fit-content !important;
}

.border-none {
  border: none;
}

.border-dashed {
  border: 1px dashed #dee2e6;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-FF7B2C {
  border: 1px solid #ff7b2c;
}

.border-DCDCE4 {
  border: 1px solid #dcdce4;
}

.border-0FA54A {
  border: 1px solid #0b2f88 !important;
}

.border-269AFF {
  border: 2px solid #269aff !important;
}

.border-r-DCDCE4 {
  border-right: 1px solid #dcdce4;
}

.border-DEE2E6 {
  border: 1px solid #dee2e6;
}

.border-12B064 {
  border: 1px solid #0b2f88 !important;
}

.border {
  border: 1px solid #868e96;
}

.border-E8E8E8 {
  border: 1px solid #e8e8e8 !important;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.h-100 {
  height: 100%;
}

.custom-btn-save {
  border-radius: 8px;
  border: none;
  background: #0b2f88;
  color: white;
  padding: 2px 15px;
  font-weight: 600;
  line-height: 28px;
}

.save-position {
  position: absolute;
  right: 30px;
  top: 80px;
}

.justify-end {
  justify-content: end;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-13px {
  font-size: 13px !important;
}

.text-14px {
  font-size: 14px !important;
}

.text-15px {
  font-size: 15px !important;
}

.text-17px {
  font-size: 17px !important;
}

.text-2 {
  font-size: 8px !important;
}

.text-3 {
  font-size: 12px !important;
}

.text-4 {
  font-size: 16px !important;
}

.text-18px {
  font-size: 18px !important;
}

.text-5 {
  font-size: 18px;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

.text-20px {
  font-size: 20px !important;
}

.text-6 {
  font-size: 22px !important;
}

.text-7 {
  font-size: 24px !important;
}

.text-8 {
  font-size: 28px !important;
}

.text-9 {
  font-size: 32px !important;
}

.text-10 {
  font-size: 36px !important;
}

.text-11 {
  font-size: 40px !important;
}

.text-12 {
  font-size: 44px !important;
}

.text-A6A7AB {
  color: #a6a7ab;
}

.text-228BE6 {
  color: #228be6;
}

.text-FF2CD0 {
  color: #ff2cd0;
}

.text-FAA300 {
  color: #faa300 !important;
}

.text-FF7B2C {
  color: #ff7b2c;
}

.text-BCE0FF {
  color: #bce0ff;
}

.text-B4EFC640 {
  color: #b4efc640;
}

.text-F6F6F9 {
  color: #f6f6f9;
}

.text-32324D {
  color: #32324d !important;
}

.text-269AFF {
  color: #269aff !important;
}

.text-F8F9FA {
  color: #f8f9fa !important;
}

.text-F24343 {
  color: #f24343;
}

.text-000000 {
  color: #000000;
}

.text-0FA54A {
  color: #0b2f88;
}

.text-8E8EA9 {
  color: #8e8ea9;
}

.text-15px {
  font-size: 15px !important;
}

.text-24A44B {
  color: #24a44b;
}

.font-urbansist {
  font-family: Roboto, sans-serif !important;
}

.text-25262B {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.002em;
}

.text-4A4A6A {
  color: #4a4a6a !important;
}

.text-666687 {
  color: #666687;
}

.text-101113 {
  color: #101113;
}

.text-212134 {
  color: #212134;
}

.text-a5a5ba {
  color: #a5a5ba;
}

.text-A5A5BA {
  color: #a5a5ba;
  font-size: 15px;
}

.text-12B064 {
  color: #0b2f88 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.icon-priority {
  font-size: 15px !important;
  border: 1px solid;
  border-radius: 50%;
}

.radius-6px {
  border-radius: 6px !important;
}

.radius-1 {
  border-radius: 4px !important;
}

.radius-2 {
  border-radius: 8px;
}

.radius-3 {
  border-radius: 12px;
}

.radius-4 {
  border-radius: 16px;
}

.radius-18px {
  border-radius: 18px !important;
}

.radius-5 {
  border-radius: 20px !important;
}

.radius-6 {
  border-radius: 24px !important;
}

.radius-42px {
  border-radius: 42px !important;
}

.radius-50 {
  border-radius: 50%;
}

.custom-radius-table {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.radius-bottom-left-right-radius {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.nav-link {
  color: #4a4a6a !important;
}

.w-fitContent {
  width: fit-content !important;
}

.max-width-900px {
  max-width: 900px !important;
}

.w-100vh {
  width: 100vh !important;
}

.w-70px {
  width: 70px !important;
}

.w-100px {
  width: 100px !important;
}

.w-130px {
  width: 130px !important;
}

.w-150px {
  width: 150px !important;
}

.w-160px {
  width: 160px !important;
}

.w-190px {
  width: 190px !important;
}

.w-200px {
  width: 200px !important;
}

.w-270px {
  width: 270px !important;
}

.w-300px {
  width: 300px !important;
}

.w-350px {
  width: 350px !important;
}

.w-220px {
  width: 220px !important;
}

.w-340px {
  width: 340px !important;
}

.w-450px {
  width: 450px !important;
}

.w-260px {
  width: 260px !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7%;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-14 {
  width: 14% !important;
}

.w-13 {
  width: 13% !important;
}

.w-15 {
  width: 15% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-20 {
  width: 20% !important;
}

.w-21 {
  width: 21% !important;
}

.w-22 {
  width: 22% !important;
}

.w-23 {
  width: 23% !important;
}

.w-25 {
  width: 25% !important;
}

.w-26 {
  width: 26% !important;
}

.w-28 {
  width: 28% !important;
}

.w-30 {
  width: 30% !important;
}

.w-32 {
  width: 32% !important;
}

.w-33 {
  width: 33%;
}

.w-35 {
  width: 35% !important;
}

.w-36 {
  width: 36% !important;
}

.w-40 {
  width: 40% !important;
}

.w-43 {
  width: 43% !important;
}

.w-44 {
  width: 44% !important;
}

.w-45 {
  width: 45% !important;
}

.w-46 {
  width: 46% !important;
}

.w-47 {
  width: 47% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-83 {
  width: 83% !important;
}

.w-80 {
  width: 80% !important;
}

.w-81 {
  width: 81% !important;
}

.w-84 {
  width: 84% !important;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-250px {
  width: 250px !important;
}

.custom-position-img {
  position: absolute;
  top: 0;
  right: 0;
}

.custom-position-img2 {
  position: absolute;
  bottom: 6px;
}

.custom-position-img2-drawer {
  position: absolute;
  bottom: -50px;
  left: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #0b2f88 !important;
  background-color: var(--ct-nav-tabs-link-active-bg);
  border-radius: 8px !important;
  border: 1px solid white !important;
}

.tab-content {
  padding: unset !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconStandard.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconStandard.MuiSelect-iconOpen.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}

.css-e363es-MuiFormControl-root {
  margin: unset !important;
}

.css-hrjh4n-MuiInputBase-root .MuiInputBase-input {
  font-size: 20px !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  padding-right: 16px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 8px !important;
  background: #e9f5ff !important;
}

.css-3cjm13-MuiFormControl-root {
  margin: unset !important;
}

.custom-switch .form-check-input:checked {
  background-color: #269aff !important;
  border-color: #269aff !important;
}

.point-input
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  min-width: 10px !important;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select {
  min-height: unset !important;
}

.css-78me8v-MuiFormControl-root {
  margin: unset !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.text-label-setting-password {
  /* //styleName: Input/Label/Placeholder; */
  font-family: Roboto, sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0px !important;
  text-align: justified !important;
  color: #a5a5ba !important;
}

.cursor {
  cursor: pointer;
}

/* 
.custom-tabs li:first-child.nav-item {
    margin-left: 48px !important;
} */

.nav-tabs .nav-link.active {
  box-shadow: 0px 3px 5px 0px #1011131a !important;
}

.custom-percent-review {
  position: absolute;
}

.footer-shadow-broup-btn {
  box-shadow: 0px 4px 120px 0px #24242414;
}

.custom-input {
  border: none !important;
  outline: none !important;
  padding: 10px !important;
  width: 100%;
}

.custom-box-select {
  position: absolute;
  background: whitesmoke;
  padding: 12px;
}

.coppy-btn-link {
  height: 30px;
  width: 30px;
}

.pl-70px {
  padding-left: 70px;
}

.box-add-reader {
  height: calc(100vh - 70px) !important;
  padding: 80px 0px 40px 0px;
  background: #eff3f1;
  overflow-y: scroll;
}

.box-select-country .css-yk16xz-control {
  height: 56px;
}

.box-select-country .css-1pahdxg-control {
  height: 56px;
}

.text-4695de {
  color: #4695de;
}

.w-35px {
  width: 35px;
}

.btn-delete-account-stripe {
  height: 50px;
  font-size: 20px;
  background: rgb(245, 74, 74);
  font-weight: 600;
  padding: 0px 12px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.text-switch-tip-tax span.MuiFormControlLabel-label {
  color: #4a4a6a;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.h-tip-tax {
  margin-top: 80px;
  height: calc(100vh - 158px);
  overflow-y: scroll;
}

.text-err {
  color: rgb(243, 53, 53);
}

.button-off-display {
  display: inline;
  height: 35px;
  border: 1px solid #999999;
  width: 100px;
  font-weight: bold;
  color: #4a4a6a;
  border-radius: 8px;
}
