.box-img-warring {
    width: 250px;
    height: auto;
}

.text-owner-not-found {
    text-align: center;
    font-size: 40px;
    color: #4a4a6a;
    font-weight: 600;
}