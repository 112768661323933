.text-name-header {
    font-family: SVN-Gilroy;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #212134;
}

.text-staff-header {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    color: #A5A5BA;
}

.status-owner {
    height: 14px;
    width: 14px;
    position: absolute;
    right: -2px;
    bottom: 1px;
    background: #3BC251;
    z-index: 1;
    border-radius: 50%;
    border: 2px solid white;

}

.modal-notification .modal-dialog.modal-90w {
    min-width: 500px !important;
    max-width: 70% !important;
    padding: 0;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.modal-notification .modal-dialog .modal-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.modal-notification .modal-dialog .modal-content .modal-body {
    padding: 0;
}

.text-notification {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.bg-eeeeee {
    background-color: #eeeeee;
    border-radius: 16px;
}

.toast-container-notification {
    border-radius: 16px;
    overflow: hidden;
    position: fixed !important;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
    margin: auto;

}

.w-70 {
    width: 70%;
}

/* .container-fluid {
    width: 100%;
    display: flex;
    justify-content: center;
} */
.modal-timeout-token .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-timeout-token .modal-dialog .modal-content {
    border-radius: 20px;
    max-width: 300px;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.text-underline {
    text-decoration: underline;
}