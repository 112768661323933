@import "./config/default/app.scss";

// Hyper Icons
@import "./icons.scss";

:root {
  --blue-rgb: #007bff;
  --green-rgb: #28a745;
  --red-rgb: #dc3545;
}

// Additional CSS
h4 {
  line-height: 1.4;
}

.lh-1 {
  line-height: 1;
}

.lh-1-5 {
  line-height: 1.5;
}

.overflow-visible {
  overflow: visible;
}

.cursor-pointer,
.cursor-pointer * {
  cursor: pointer;
}

.text-justify {
  text-align: justify !important;
}

.form-check .form-check-input {
  margin-left: 0;
  flex-shrink: 0;
}

.aspect-ratio-1 {
  aspect-ratio: 1;
}

.aspect-ratio-16-9 {
  aspect-ratio: 16/9;
}

.aspect-ratio-16-11 {
  aspect-ratio: 16/11;
}

.object-cover {
  object-fit: cover;
}

.rounded-4 {
  border-radius: 0.5rem;
}

.rounded-5 {
  border-radius: 0.75rem;
}

.rounded-6 {
  border-radius: 1rem;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.smooth-transition {
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  -moz-transition: 0.3s all ease;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.nav-second-level .menuitem-active,
.nav-second-level a {
  background-color: #fff !important;
}

.circle-deco {
  --size: 20rem;
  height: var(--size);
  width: var(--size);
}

.bg-white-gradient {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

.customer-overview-info .circle-deco {
  right: -5rem;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  mix-blend-mode: soft-light;
  top: 3rem;
}

.resize-none {
  resize: none;
}

.square-btn {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
}

.form-switch .form-check-input {
  margin-right: 0.5rem;
}

.swal2-container.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.dropdown-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.form-check .form-check-label {
  margin-left: 8px;
}

.datepicker-modal {
  top: 0;
}

.datepicker-modal .datepicker-content .datepicker-col-1:first-child {
  display: none;
}

.datepicker-modal .datepicker-content .datepicker-col-1:nth-child(2) {
  order: 2;
}

.datepicker-modal .datepicker-content .datepicker-col-1:last-child {
  order: 1;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  pointer-events: none;
}

.backdrop.active {
  visibility: visible;
  pointer-events: all;
}

.cart-info {
  width: 300px;
  overflow-y: auto;
  -webkit-transform: translateX(300px);
  -ms-transform: translateX(300px);
  transform: translateX(300px);
  z-index: 30;
}

.cart-info.active {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: aliceblue;
  opacity: 1;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  color: #0b2f88 !important;
}

.react-table th,
.react-table td {
  padding: 10px;
}

.react-table td {
  white-space: unset;
}

.text-pgk-large {
  color: #ef5cab;
}

.text-pgk-super {
  color: #9544de;
}

.text-pgk-basic {
  color: #787878;
}

.loading-modal .modal-content,
.loading-modal .modal-content .modal-body {
  background-color: transparent;
}

.modal-loading-backdrop,
.loading-dialog {
  z-index: 999999;
}

.text-one-line {
  line-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}

.text-two-line {
  line-height: 23px;
  -webkit-line-clamp: 2;
  height: 50px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}

.text-two-line-name-store {
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}

.text-three-line {
  line-height: 25px;
  -webkit-line-clamp: 3;
  height: 75px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}

@media (min-width: 1200px) {
  .table-responsive {
    overflow: visible;
  }

  .datepicker-modal {
    --size: 280px;
    width: var(--size) !important;
    height: var(--size) !important;
    background: transparent !important;
  }

  .datepicker-modal {
    right: 17.2rem !important;
    bottom: 3rem !important;
  }

  .customer-type-nav .nav-link {
    font-size: 12px;
  }

  .qr-code-col,
  .change-password-col {
    flex: 0 0 auto;
    width: 45%;
  }
}

@media (min-width: 1300px) {
  .datepicker-modal {
    right: 18.5rem !important;
    bottom: 3.8rem !important;
  }

  .customer-type-nav .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1400px) {
  .datepicker-modal {
    right: 20.2rem !important;
    bottom: 5.8rem !important;
  }

  .customer-type-nav .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1600px) {
  .datepicker-modal {
    right: 23.4rem !important;
    bottom: 6rem !important;
  }

  .customer-type-nav .nav-link {
    font-size: 14px;
  }
}

@media (min-width: 1900px) {
  .datepicker-modal {
    right: 28.4rem !important;
    bottom: 7rem !important;
  }

  .customer-type-nav .nav-link {
    font-size: 14px;
  }

  .qr-code-col,
  .change-password-col {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

@media (max-width: 575.98px) {
  ul[role="tablist"] {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  ul[role="tablist"] .nav-item {
    flex-shrink: 0;
  }

  ul[role="tablist"] .nav-item .nav-link {
    --size-paddingY: 7px;
    --size-paddingX: 10px;
    margin-top: 0 !important;
    padding-top: var(--size-paddingY) !important;
    padding-bottom: var(--size-paddingY) !important;
    padding-left: var(--size-paddingX) !important;
    padding-right: var(--size-paddingX) !important;
  }

  .btn-sm-relative {
    position: relative !important;
  }

  .w-xs-100 {
    width: 100%;
  }

  .datepicker.ios {
    position: fixed !important;
  }

  .datepicker-modal {
    position: fixed !important;
    background-color: rgba(0, 0, 0, 0.4) !important;
  }

  .birthday-page {
    min-height: unset !important;
    height: 100vh;
    overflow-y: hidden;
  }

  .food-block .col-sm-12 {
    width: 50%;
  }

  .food-item-wrap {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background-color: #fff !important;
  }

  .setting-birthday {
    border: 0 !important;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 190px;
  background-color: #0b2f88 !important;
  overflow-y: none !important;

  &__logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
  }

  &__menu {
    position: relative;
    padding-left: 12px;

    &__item {
      display: flex;
      align-items: center;
      place-content: flex-start;
      padding: 12px 0px 12px 8px;
      font-size: 1.25rem;
      font-weight: 500;
      color: #555555;
      transition: color 0.3s ease-in-out;
      color: white;
      width: 100%;

      &.active {
        color: #fff;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background-color: #3460cb;
        transition: 0.5s;
      }

      &__icon {
        border-radius: 10px;
        height: 44px;
        width: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
        aspect-ratio: 1/1;

        &.active {
          background-color: white;
          color: #fe841b;
        }
      }

      &__text {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        color: white;
      }
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      border-radius: 10px;
      background-color: #3460cb;
      z-index: -1;
      transform: translateX(-50%);
      transition: 0.3s ease-in-out;
    }
  }
}
