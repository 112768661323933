.payment-vnpay {
  background-color: #0b2f888f;
  height: 100vh;
  width: 100%;
  padding: 16px 12px;
}
.info-payment {
  width: 100%;
  padding: 16px 12px;
  border-radius: 12px;
  background-color: white;
}
