.board {
  position: relative;
  width: 400px;
  height: 400px;
  border: 1px solid black;
  margin: auto;
}

.snake-dot {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: black;
}

.food {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: red;
}

.score {
  text-align: center;
}

.game-over {
  text-align: center;
  margin-top: 50px;
}