@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Edu+TAS+Beginner&family=Poppins:wght@200&display=swap");

.text-your-subscription {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #4a4a6a;
}

.text-content-you-are-using {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.box-range-sms {
  width: 100%;
  height: auto;
  border-radius: 12px;
  padding: 16px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff7e8;
}

.mb-6 {
  margin-bottom: 24px;
}

.text-this-month {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.text-4A4A6A-owner {
  color: #4a4a6a;
}

.range-box-subscription {
  width: 100%;
  height: 12px;
  border-radius: 120px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.range-slide-subscription {
  width: 10%;
  height: 12px;
  border-radius: 120px;
  background-color: #faa300;
}

.text-number-slide-subscription {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.box-car-visa {
  padding: 8px 24px;
  border: 1px solid #dcdce4;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-your-card-subsription {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.text-update-card-visa {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.text-subsription-renew {
  font-family: Roboto, sans-serifs;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.text-cancel-subscription {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
  text-decoration: underline;
}

.box-hotline {
  padding: 12px 32px;
  border: 1px solid #ff7b2c;
  box-shadow: 0px 4px 36px 0px #24242414;
  border-radius: 120px;
  position: absolute;
  bottom: -35px;
  right: 24px;
  display: flex;
  gap: 8px;
  background: white;
}

.text-ultimate {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #ff7b2c;
}

.text-phonenumber-hotline {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #212134;
}

.text-best-choice-subscription {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #4a4a6a;
}

.box-option-sms {
  height: 43px;
  padding: 5px;
  border-radius: 6px;
  gap: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
}

.option-sms-checked {
  padding: 2px 12px;
  height: 33px;
  border-radius: 4px;
  box-shadow: 0px 3px 5px 0px #1011131a;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-sms {
  padding: 2px 12px;
  height: 33px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gap-6 {
  gap: 24px !important;
}

.text-option-sms {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
}

.box-subscription-card {
  position: relative;
  height: fit-content;
  width: fit-content;
}

.box-subscription-card-ladding-page {
  position: relative;
  width: fit-content;
  height: 450px;
}

.content-card-subsctiption {
  position: absolute;
  top: 0;
  right: 0;
  height: fit-content;
  width: 100%;
  padding: 32px;
}

.text-name-card-subscription {
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: white;
}

.text-type-card {
  font-family: DM Sans, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.text-content {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: 90px;
}

.text-price-subscription-card {
  font-family: DM Sans, sans-serif;
  font-size: 54px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.text-price-hand {
  font-family: "Caveat", cursive;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffe7bb;
}

.text-what-included {
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.text-sms-have-in-buy {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.box-btn-upgrade {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 0px;
  margin-top: 29px;
}

.text-upgrade {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.height-card-subscription {
  height: calc(100% - 157px);
}

.text-lable-reason-cancel {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.bg-F24343 {
  background-color: #f24343;
}

.box-img-subscription {
  height: 72px;
  width: 72px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  flex-shrink: 0;
}

.img-subscription {
  height: 38px;
  width: 38px;
}

.text-note-subcription {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
  text-decoration: underline;
}

.text-content-note-subcription {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: rgb(122, 122, 122);
}

.w-440px {
  width: 440px;
}

.height-100-255px {
  height: calc(100vh - 255px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.box-subscription-card {
  height: 500px;
}
