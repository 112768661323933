.custom-content-createstaff {
  height: calc(100vh - 80px) !important;
}

.custom-bg-select .react-select__control.css-yk16xz-control {
  background: #f6f6f9 !important;
  height: 35px;
  padding-left: 12px !important;
}

.custom-cross-select
  .react-select__indicator.react-select__clear-indicator.css-tlfecz-indicatorContainer {
  display: none !important;
}

.custom-switch-size input {
  font-size: 20px !important;
}

.custom-radius-pre {
  font-size: 28px;
  padding: 0px 16px;
  color: #0b2f88;
}

.custom-radius-next {
  font-size: 28px;
  padding: 0px 14px;
  color: #0b2f88;
}

.color-raius-btn.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #0b2f88 !important;
}

.custom-select.form-select {
  border: unset !important;
  background-color: #f6f6f9;
  height: 40px !important;
}

.custom-bg-white.form-select {
  background-color: white;
  border: unset !important;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #32324d;
}

.h-30px {
  height: 30px;
}

.w-30px {
  width: 30px;
}

.custom-radio-staff input {
  height: 30px;
  width: 30px;
}

.border-mui-none .MuiInputBase-root fieldset {
  border: none !important;
}

.border-mui-none .MuiInputBase-root input {
  padding: 0 !important;
}

.custom-input-clockin-out {
  width: 100px;
}

.custom-input-clockin-out input {
  padding: 10px;
}

.custom-input-clockin-out fieldset {
  border-radius: 6px !important;
  border: 1px solid #dee2e6;
}

.w-290px {
  width: 290px;
}

.custom-input-salary {
  border-radius: 8px;
  border: 1px solid #dee2e6;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: #212134;
  padding: 0px 8px;
}

.custom-input-salary:focus-visible {
  border: 1px solid #dee2e6;
  outline: none;
}

.custom-select-role {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.002em;
  text-align: left;
  color: #212134;
}

.change-avt-staff {
  border: 2px dashed #0b2f88;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  justify-content: center;
}

.MuiStack-root.css-4jnixx-MuiStack-root {
  padding: 0;
}

.MuiStack-root.css-1rv8zyt-MuiStack-root {
  padding: 0;
}

.radius-50 {
  border-radius: 50%;
}

.w-150px {
  width: 150px !important;
  min-width: 150px !important;
}

.h-100vh-240px {
  height: calc(100vh - 240px) !important;
  overflow-y: scroll;
}
