.padding-drawer-History {
  padding: 48px 24px !important;
}

.custom-w-drawer-history
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 70% !important;
}

.dropzone {
  border: 2px dashed #0b2f88;
  padding: 4px 36px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 96px;
  height: 50px;
  justify-content: center;
  min-height: 100px;
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
}

.custom-m-b.table {
  margin-bottom: unset !important;
}

.custom-position-oder-detail {
  position: absolute;
  bottom: -56px;
  left: 0;
}

.t-body-table-history-order {
  display: table-caption;
  height: calc(100vh - 550px) !important;
  overflow-y: scroll !important;
  width: 100%;
}

.text-align-end {
  text-align: end !important;
}

.h-content-orderdetail {
  height: calc(100vh - 610px) !important;
  overflow-y: scroll;
}

.text-269AFF {
  color: #269aff;
}
