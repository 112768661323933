.choose-time-reservation fieldset {
  border: none;
}

.choose-time-reservation .MuiInputBase-root {
  height: 42px !important;
}

.choose-time-reservation .MuiInputBase-root input {
  height: 42px !important;
  padding: 0 12px;
  color: #4a4a6a;
}