.input-last-year {
  width: 100%;
  border-radius: 8px;
  height: 40px;
  border: 1px solid #999999;
  padding: 0px 12px;
}
.input-last-year:focus-visible {
  outline: none;
}
