.text-content-modal-delete-customer {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.btn-cancel-modal-delete-customer {
  border-radius: 8px;
  width: 48%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #dee2e6;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.border-delete {
  border: 1px solid #f24343 !important;
}

.text-delete-modal-customer-btn {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content:nth-child(1) {
  border-radius: 20px;
  border: 1px solid #d4d4d4;
}

.modal-delete-customer
  .modal-dialog.modal-dialog-centered
  .modal-content
  .modal-dialog
  .modal-content {
  border-radius: 20px;
  border: none;
}

.img-leaf1 {
  position: absolute;
  top: -35px;
  right: -17px;
}

.img-leaf2 {
  position: absolute;
  bottom: -26px;
  left: -17px;
}

.otp-input div div input[type="number"],
.otp-input div div {
  background: #f4f6fa;
  border-radius: 12px;
  width: 120px !important;
  height: 40px;
  border: 1px solid rgb(201, 201, 201);
}
.otp-input div div input[type="number"],
.otp-input div div {
  background: #f4f6fa;
  border-radius: 12px;
  width: 120px !important;
  height: 40px;
  border: 1px solid rgb(201, 201, 201);
}

.otp-input div div input:active,
.otp-input div div input:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.otp-input div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.otp-input div div {
  display: flex;
  justify-content: center;
}

.otp-input div div input {
  border: none;
  background-color: transparent;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #a6a7ab;
}

.otp-input div div input::placeholder {
  color: #a6a7ab;
}

.input-form .native-input {
  height: 100%;
}

.text-add-floor {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.box-input-add-floor {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.input-add-floor {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.input-add-floor::placeholder {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.btn-input-add-floor {
  padding: 0px 16px;
  height: 42px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.w-466px {
  width: 466px;
}

.img-leaf1-setup-table {
  position: absolute;
  top: -35px;
  right: -17px;
}

.text-has-floor {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.text-A5A5BA {
  color: #a5a5ba;
}

.text-12B064 {
  color: #0b2f88;
}

.btn-edit-delete-floor {
  height: 58px;
  width: 96px;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: white;
}

.btn-save-cancel-floor {
  width: 48%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.border-0FA54A {
  border: 1px solid #0b2f88;
}

.bg-0FA54A {
  background-color: #0b2f88;
}

.box-btn-option-add-table {
  height: 76px;
  width: 100%;
  border-radius: 12px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  box-shadow: 0px 4px 36px 0px rgba(36, 36, 36, 0.08);
}

.btn-option-add-table {
  height: 60px;
  width: 48%;
  background: #0b2f88;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.btn-option-add-table-2 {
  height: 60px;
  width: 48%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.input-add-table {
  height: 42px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  color: #4a4a6a;
}

.ex-date-gift-card {
  overflow: hidden;
  height: 60px;
  width: 150px;
  border-radius: 12px;
  border: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  color: #4a4a6a;
}

.text-input-add-table {
  /* //styleName: Price/Input/Label/Active; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #4a4a6a;
}

.text-input-add-table::placeholder {
  /* //styleName: Input/Label/Active; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: justified;
  color: #a5a5ba;
}

.text-add-table-name {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.btn-remove-plus-number {
  height: 40px;
  width: 44px;
  border-radius: 42px;
  border: 1px solid #0b2f88;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-number-table-multi {
  height: 42px;
  width: 100px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.input-gift-card {
  height: 42px;
  width: 200px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 20px;
}

.input-number-table-multi > input {
  width: fit-content;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
  background: transparent;
}

.input-gift-card > input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
  background: transparent;
}

.input-show-list-gift-card {
  height: 42px;
  width: 100px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 20px;
}

.input-show-list-gift-card > input {
  max-width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
  background: transparent;
}

.input-setting-point {
  height: 42px;
  width: 60px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 10px;
}

.input-setting-point > input {
  max-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
  background: transparent;
}
