.ReactFlagsSelect-module_selectBtn__19wW7 {
    border: unset !important;
}

.custom-h-38px .react-select__control.css-yk16xz-control {
    height: 38px !important;
}

.form-group.border-none .react-datepicker-wrapper .react-datepicker__input-container .input-group .input-group-text {
    border: none;
}

.w-1-2 {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .max-w-768-w-full {
        width: 100%;
    }

    .ReactFlagsSelect-module_label__27pw9 {
        display: none;
    }

    .flex-column-768 {
        flex-direction: column;
    }
}