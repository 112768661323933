.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 950px;
  max-width: none;
  height: 100vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 950vh;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -880px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.text-table-pay-bill-2 {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}

.text-table-pay-bill-plus {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}

.accordion-button {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../../../assets/images/choose-menu/chevron-up.svg");
}

.accordion-button::after {
  background-image: url("../../../../assets/images/choose-menu/chevron-up.svg");
}

.text-bill-pay-bill {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
}

.text-212134 {
  color: #212134;
}

.text-FAA300 {
  color: #faa300;
}

.footer-pay-bill-table {
  height: 480px;
  width: 50%;
  background-color: white;
  /* box-shadow: 0px 4px 240px 0px rgba(53, 53, 53, 0.06); */
  display: flex;
  justify-content: space-between;
  padding: 20px 15px 0px 15px;
}

.bg-FEFEFE {
  background-color: #fefefe;
}

.body-modal-pay-bill-table {
  height: calc(100vh - 74px);
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.container-pay-pill-table {
  height: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
  width: 50%;
}

.h-100vh-312px {
  height: calc(100vh - 312px);
}

.w-460px {
  width: 460px;
}

.h-100vh-60px {
  height: calc(100vh - 60px);
}

.border-right {
  border-right: 1px solid #eaeaef;
}

/* .custom-typing-discount {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #EAEAEF;
    border-radius: 16px;
    overflow: hidden;
} */

.custom-icon-typing-discount {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #dcdce4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dcdce4;
}

.border-FF7B2C {
  border: 1px solid #ff7b2c;
}

.text-FF7B2C {
  color: #ff7b2c;
}

.custom-choose-payment-method {
  background-color: white;
  border: 1px solid #dcdce4;
  border-radius: 16px;
  /* padding: 12px; */
  height: 46px;
}

.custom-choose-payment-method-2 {
  background-color: #fff2ea;
  border: 1px solid #fff2ea;
  border-radius: 16px;
  /* padding: 12px; */
  height: 46px;
}

/* .bill-payment-confirm {
  height: 100%;
  width: 100%;
  background-image: url(../../../../assets/images/web-owner/bacground-border-pay-pill.svg);
  background-repeat: round;
  background-color: white;
} */

.name-payment-bill {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: #a5a5ba;
}

.price-payment-bill {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  color: #4a4a6a;
}

.dollar-payment-bill {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  color: #8e8ea9;
}

.mb-60px {
  margin-bottom: 60px;
}

.price-payment-confirm {
  /* //styleName: Price/Large; */
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  /* text-align: left; */
  color: #ff7b2c;
}

.text-submit-payment-bill {
  font-family: SVN-Gilroy;
  font-size: 26px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.bg-FE841B {
  background-color: #fe841b;
}

.accordion-button:not(.collapsed) {
  color: #4a4a6a !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn-close-modal {
  position: absolute;
  height: 48px;
  width: 48px;
  top: 0px;
  left: -20px;
  background-color: #ffffff;
  border-radius: 8px;
}

.mt-20px {
  margin-top: 20px;
}

.p-20px {
  padding: 20px;
}
