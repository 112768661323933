.modal-slide-img {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.modal-slide-img .modal-dialog.modal-dialog-centered .modal-content {
  width: 60vw !important;
  border-radius: 20px;
  height: 80vh;
}

.modal-slide-img
  .modal-dialog.modal-dialog-centered
  .modal-content
  .modal-body {
  height: 100%;
}
.object-fit-fill {
  object-fit: fill;
}

.name-food-add-to-pos-menu {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.object-fit-cover {
  object-fit: cover;
}

.height-170px {
  height: 170px;
}

.text-price-modal-food-pos-menu {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.text-modal-dollar-pos-menu {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.btn-add-card-modal {
  width: 100%;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  background-color: #0b2f88;
  height: 42px;
}
.btn-togo-add-food {
  padding: 0px 12px;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
  background-color: white;
  height: 42px;
  border: 1px solid #999999;
}
.btn-togo-add-food-2 {
  padding: 0px 12px;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  background-color: #ff7b2c;
  height: 42px;
}

.text-choose-status-order-modal {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: #4a4a6a;
}

.border-FF7B2C {
  border: 1px solid #ff7b2c;
}

.bg-FF7B2C {
  background-color: #ff7b2c;
}

.box-img-add-food-owner {
  width: 250px;
  height: 150px;
}
.each-slide-effect {
  width: 100%;
  height: 100%;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.react-slideshow-wrapper slide {
  /* width: 60vw; */
}
.react-slideshow-container {
}
.box-img-slider > div {
  width: 100%;
  height: 100%;
}
.box-img-slider > div .react-slideshow-container {
  width: 100%;
  height: 100%;
}
.box-img-slider
  > div
  .react-slideshow-container
  .react-slideshow-wrapper.slide {
  width: 100%;
  height: 100%;
}
.box-img-slider
  > div
  .react-slideshow-container
  .react-slideshow-wrapper.slide
  .images-wrap {
  height: 100%;
}
.box-img-slider
  > div
  .react-slideshow-container
  .react-slideshow-wrapper.slide
  .images-wrap
  > div {
  height: 100%;
}
