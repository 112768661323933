.pt-118px {
  padding-top: 118px;
}

.pb-48px {
  padding-bottom: 48px;
}

.rounded-18px {
  border-radius: 18px;
}

.box-shadow-inventory {
  box-shadow: 0px 4px 120px 0px #24242414;
}

.text-inventory {
  /* //styleName: Heading/Heading 2; */
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #4a4a6a;
}

.bg-F8F9FA {
  background-color: #f8f9fa;
}

.text-fill-table-inventory {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.px-16px {
  padding-left: 16px;
  padding-right: 16px;
}

.box-choose-fill-table-inventory {
  background-color: white;
  box-shadow: 0px 3px 5px 0px #1011131a;
  border-radius: 8px;
}

.btn-option-table-inventory {
  height: 42px;
  border: 1px solid #0b2f88;
  border-radius: 6px;
  gap: 8px;
  padding: 0 12px;
  display: flex;
  align-items: center;
}

.text-btn-option-table-inventory {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.gap-12px {
  gap: 12px;
}

.w-8 {
  width: 8%;
}

.w-12 {
  width: 12%;
}

.box-display-status-1 {
  height: 4px;
  width: 40px;
  border-radius: 1px 0px 0px 1px;
  background-color: #dcdce4;
}

.box-display-status-2 {
  height: 4px;
  width: 40px;
  border-radius: 0px 1px 1px 0px;
  background-color: #dcdce4;
}

.bg-FF7B2C {
  background-color: #ff7b2c;
}

.bg-0FA54A {
  background-color: #0b2f88;
}

.title-status-inventory {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.w-4 {
  width: 4%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.modal.left .modal-dialog,
.modal.right-inventory-modal .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  max-width: none;
  height: 100vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right-inventory-modal .modal-content {
  height: 950vh;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right-inventory-modal .modal-body {
  padding: 0;
}

/*Right*/
.modal.right-inventory-modal.fade .modal-dialog {
  right: -400px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right-inventory-modal.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.box-btn-print-inventory {
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  right: 0;
}

.btn-print-inventory {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #ff7b2c;
  background-color: #fff2ea;
  /* //styleName: Heading/Heading 3; */
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
  padding: 8px 0px;
}

tbody.body-table-inventory-preview {
  display: table-caption;
  height: calc(100vh - 200px) !important;
  overflow-y: scroll;
  width: 100%;
}

.select-unit-new-material {
  /* //styleName: Price/Input/Label/Placeholder; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.select-unit-new-material::placeholder {
  color: #a5a5ba;
}

.modal-delete-customer.modal-import-inventory .modal-dialog {
  max-width: 750px !important;
  width: 750px !important;
}

.mt-30px {
  margin-top: 30px;
}

.text-ff7b2c {
  color: #ff7b2c !important;
}

.text-function-inventory {
  color: #a5a5ba;
  font-size: 20px;
  font-weight: 600;
  font-family: sans-serif;
}

.text-input-usd {
  /* //styleName: Price/Input/Label/Placeholder; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.bg-fafafa {
  background-color: #fafafa;
}

.text-note-import-inventory {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-content-import-inventory {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-title-import-inventory {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.radio-choose-option-inventory label {
  /* //styleName: Heading/Heading 4; */
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.radio-choose-option-inventory.text-green label {
  color: #0b2f88 !important;
}

.text-date-unit-inventory {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.mt-18px {
  margin-top: 18px;
}

.btn-import-inventory-footer {
  height: 42px;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
  border-radius: 6px;
  border: 1px solid #dcdce4;
}

.react-select__placeholder.css-1wa3eu0-placeholder {
  color: #4a4a6a !important;
}

.opacity-07 {
  opacity: 0.7;
}
