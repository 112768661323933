.btn-footer-add-dish-group {
  padding: 12px 24px;
  color: white;
  border-radius: 12px;
  font-weight: 600;
  font-size: 18px;
}

.btn-footer-add-dish-group:nth-child(1) {
  background-color: rgb(248, 97, 97);
}

.btn-footer-add-dish-group:nth-child(2) {
  background-color: green;
}