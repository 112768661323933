.btn-back-flappybird {
  background: #e69119;
  color: #fff;
  width: 100px;
  padding: 0.5rem 0px;
  border-radius: 1rem;
  border: 4px solid #fff;
  font-family: "Fredoka One", cursive;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-menu-flappy-brid {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-end-game {
  width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end-game-container {
  background: #f9e4c6;
  color: #fff;
  width: 90%;
  padding: 0.5rem 0px;
  border-radius: 1rem;
  border: 4px solid #fff;
  font-family: "Fredoka One", cursive;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text-score-game {
  font-family: "Fredoka One", cursive;
  font-weight: 600;
  color: #11151f;
  font-size: 26px;
}