.keyboard-passcode {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #999999;
  border-radius: 6px;
  font-weight: 700;
  color: #4a4a6a;
  font-size: 20px;
}
.box-keyboard-passcode {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  margin-top: 20px;
}
