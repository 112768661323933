.h-55px {
  height: 55px;
}

.w-55px {
  width: 55px;
}

.name-food-order-items {
  /* //styleName: Heading/Heading 5; */
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.maxwidth-78px {
  max-width: 78px;
}

.maxwidth-110px {
  max-width: 110px;
}
.maxwidth-100px {
  max-width: 100px;
}

.maxwidth-130px {
  max-width: 130px;
}

.name-price-food-order-items {
  /* //styleName: Subtitle/Subtitle 1; */
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #8e8ea9;
}

.name-dollar-food-order-items {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #8e8ea9;
}

.rounded-42px {
  border-radius: 42px;
}

.w-44px {
  width: 44px;
}

.number-food-order {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.002em;
  text-align: center;
  color: #8e8ea9;
}

.w-42px {
  width: 42px;
}

.note-food-order {
  /* //styleName: Body/Body 2; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #666687;
  margin-left: 67px;
}

.scroll-hidden::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.border-items-food-order {
  border-bottom: 1px solid #b5b5b6;
}

.btn-type-togo-order-true {
  height: 40px;
  width: 80px;
  border-radius: 6px;
  background-color: darkorange;
  color: white;
  font-weight: 700;
  border: 1px solid rgb(97, 53, 0);
}
.btn-type-togo-order-false {
  height: 40px;
  width: 80px;
  border-radius: 6px;
  background-color: #99999906;
  color: #4a4a6a;
  font-weight: 700;
  border: 1px solid #999999;
}

.note-food-item {
  width: 100%;
  height: 50px;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  border: 1px solid #dcdce4;
  gap: 10px;
  color: #424242;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
