.modal.left .modal-dialog,
.modal.right-customer .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  max-width: none;
  height: 100vh;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right-customer .modal-content {
  height: 950vh;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right-customer .modal-body {
  padding: 0;
}

/*Right*/
.modal.right-customer.fade .modal-dialog {
  right: -400px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right-customer.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.box-name-table-customer {
  height: 50px;
  width: 78px;
  border-radius: 12px;
  background-color: #ff7b2c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
}

.order-customer {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}

.id-order-customer {
  font-family: Roboto, sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #faa300;
}

.title-infor-bill-customer {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #666687;
}

.title-infor-bill-customer-print {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.content-infor-bill-customer {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.text-view-bill {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #0b2f88;
}

.custom-view-bill {
  height: 22px;
  position: absolute;
  bottom: -22px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
}

.btn-view-bill {
  display: flex;
  gap: 2px;
  align-items: center;
  height: 22px;
  width: 229px;
  border-radius: 0px 0px 24px 24px;
  background-color: #f3fbf7;
  justify-content: center;
}

.rorate-180 {
  rotate: 180deg;
}

.title-pay-bill-customer {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #212134;
}

.line-height-normal {
  line-height: normal !important;
}

.price-pay-bill-customer {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #a5a5ba;
}

.pt-22px {
  padding-top: 22px;
}

.delete-bill-customer {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f24343;
}

.btn-print-bill-customer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f8f9fa;
  background-color: #ff7b2c;
  border-radius: 6px;
  margin-top: 10px;
}

.btn-delete-bill-order {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #f8f9fa;
  background-color: #ff3e3b;
  border-radius: 6px;
  margin-top: 10px;
}

.custom-h-food-bill-customer-1 {
  height: calc(100vh - 490px);
}

.custom-h-food-bill-customer-2 {
  height: calc(100vh - 470px + 86px);
}

.price-pay-bill-customer-dollar {
  font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}

.text-8E8EA9 {
  color: #8e8ea9;
}

.text-FF7B2C {
  color: #ff7b2c;
}

.price-pay-bill-customer-total {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.h-100vh {
  height: 100vh;
}

.text-black {
  color: black !important;
}

.border-black {
  border: 1px solid black !important;
}

.border-bottom-black {
  border-bottom: 1px dashed black !important;
}

.h-78px {
  height: 78px;
}
