.pt-70px {
  padding-top: 70px;
}

.custom-icon-copy {
  height: 30px;
  width: auto;
}

.btn-update-config {
  height: 50px;
  padding: 0px 24px;
  border-radius: 12px;
  background-color: #0b2f88;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  width: fit-content;
}

.box-copy-success {
  background-color: rgb(0, 0, 0, 0.5);
  color: white;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 8px;
  margin: 0 auto;
}

.box-container-copy-success {
  width: calc(100% - 190px);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50px;
  z-index: 10000;
}

.rounded-02rem {
  border-radius: 0.2rem;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.btn-function-group-customer {
  height: 30px;
  padding: 0px 16px 0px 16px;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  color: white;
  font-family: "Roboto", sans-serif;
}

.btn-function-group-customer:nth-child(1) {
  background-color: #0b2f88;
}

.btn-function-group-customer:nth-child(2) {
  background-color: #4a8adf;
}

.btn-function-group-customer:nth-child(3) {
  background-color: #dc936f;
}

.modal-rounded-16px .modal-content {
  border-radius: 16px;
}

.text-414151f {
  color: #14141f;
}

.focus-visiable-none:focus-visible {
  outline: none;
}

.h-120px {
  height: 120px;
}

.table-device-customer {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.table-device-customer thead tr th {
  position: sticky;
  top: 0px;
  z-index: 999;
}

.shadow-table {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.bg-green-light {
  background-color: #caf8dd;
}

.modal-infor-device-order .modal-dialog .modal-content {
  border-radius: 20px;
}

.container-group-customer {
  padding-top: 70px;
  height: calc(100vh);
  background-color: white;
}
.react-select__menu {
  z-index: 999999 !important;
  /* position: relative !important; */
  background-color: red;
}
.z-index--99 {
  z-index: 99;
}

.setting-info {
  font-size: 1.5rem;
  margin: 0;
  line-height: 75px;
  color: #323a46;
}

.input-setting-info {
  border: 1px solid #ced4da;
  height: 37px;
  padding: 0px 14.4px;
  color: #6c757d;
}
.input-setting-info:focus-visible {
  outline: none;
}

.feedback-admin {
  height: calc(100vh - 90px);
}
.h-200px {
  height: 200px;
}
.w-200px {
  width: 200px;
}
.bg-light-200 {
  background-color: #ececec;
}
.feedback-admin-name {
  color: #4a4a6a;
  font-size: 16px;
  font-weight: 600;
}
.feedback-admin-phone {
  color: #4a4a6a;
  font-size: 14px;
  font-weight: 500;
}
.feedback-admin-email {
  font-size: 14px;
  font-weight: 500;
  color: #c5221f;
}
.feedback-admin-content {
  font-size: 14px;
  font-weight: 500;
  color: #464646;
}
.time-feedback-admin {
  font-size: 18px;
  font-weight: 600;
  color: #4a4a6a;
}
.feedback-admin-item {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pagination-feedback .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  width: 100%;
}
.pagination-feedback p {
  margin: 0 !important;
}
.content-feedback-admin {
  height: calc(100vh - 157px);
  overflow-y: auto;
  padding: 0px 12px;
}
.list-img-feedback {
  width: 100%;
  display: flex;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; */
  gap: 24px;
}
.page-choose-pos {
  height: calc(100vh - 80px);
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 20px;
  padding: 24px;
}
.pos-item {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  border-radius: 12px;
  padding: 24px;
  height: fit-content;
}
.icon-pos {
  font-size: 90px !important;
  color: #0b2f88;
}
.text-name-pos {
  color: #343449;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.text-content-pos {
  color: #4a4a6a;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.button-pos-item {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  color: white;
  background-color: #0b2f88;
  font-weight: bold;
  font-size: 18px;
  border: none;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px !important;
  margin-top: 12px;
}

.important-main-pos {
  background-color: #f7ebb9;
  color: rgb(214, 84, 84);
  padding: 8px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
}
