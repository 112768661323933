.btn-create-voucher {
    height: 42px;
    padding: 0px 12px;
    border-radius: 6px;
    background-color: #12B064;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    /* //styleName: Body/Body 1; */
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
}

.py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
}

.w-36px {
    width: 36px;
}

.text-status-voucher {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
}

.text-12B064 {
    color: #12B064;
}

.text-1E7BCC {
    color: #1E7BCC;
}

.bg-E8F8F0 {
    background-color: #E8F8F0;
}

.bg-E9F5FF {
    background-color: #E9F5FF;
}

.rounded-4px {
    border-radius: 4px;
}

.body-table-gift-card {
    display: table-caption !important;
    height: calc(100vh - 380px) !important;
    overflow-y: scroll;
    width: 100%;
}
.body-table-voucher {
    display: table-caption !important;
    height: calc(50vh - 200px) !important;
    overflow-y: scroll;
    width: 100%;
}
/* .body-pos-station {
    max-height: calc(100vh - 280px) !important;
    overflow-y: scroll;
    width: 100%;
} */


.h-52px {
    height: 52px;
}

.position-leaf1-voucher {
    position: absolute;
    top: 0;
    right: 0;
}

.position-leaf2-voucher {
    position: absolute;
    bottom: 20px;
    left: 0;
}

.btn-delete-edit-staff {
    height: 30px;
    width: 50px;
    border-radius: 6px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pt-90px {
    padding-top: 90px;
}

.radio-voucher .MuiFormControlLabel-label {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #4A4A6A;
}

.content-checkbox-voucher,
.note-checkbox-voucher {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #4A4A6A;
    margin: 0;
}

.note-checkbox-voucher {
    margin-top: 6px;
}

.box-checkbox-voucher {
    padding: 8px;
    border-radius: 12px;
    background-color: #ffe477;
}

.code-name-voucher {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #4A4A6A;
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #4A4A6A;
}

.code-name-voucher:focus-visible {
    outline: none;
}

.w-19 {
    width: 19%;
}