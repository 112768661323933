.btn-footer-add-dish-group {
  padding: 12px 24px;
  color: white;
  border-radius: 12px;
  font-weight: 600;
  font-size: 18px;
}

.btn-footer-add-dish-group:nth-child(1) {
  background-color: rgb(248, 97, 97);
}

.btn-footer-add-dish-group:nth-child(2) {
  background-color: green;
}

.shadow-card-default {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.list-side-dish-active {
  width: 300px;
  border-left: 2px solid gray;
  flex-shrink: 0;
}

.aspect-ratio {
  aspect-ratio: 1/1;
}

.box-choose-side-dish-owner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px !important;
  max-height: 600px;
  height: fit-content !important;
  padding: 20px 0px;
}

.border-gray {
  border: 1px solid gray;
}

.btn-add-remove-side-dish {
  padding: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #f87103;
  background-color: #fde9d8;
  border-radius: 50%;
}

.line-height-normal {
  line-height: normal;
}