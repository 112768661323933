.chair-in-table {
  width: 18px;
  height: 6px;
  border-radius: 12px;
  border: 1px;
  background-color: #ffffff;
}

.circle-name-table {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.table-pos {
  padding: 16px;
  background: #ffffff;
  cursor: pointer;
  position: relative;
}

.paid {
  background-color: #e7f6ed;
  color: #0b2f88;
}

.reserved {
  background-color: #fff3e8;
  color: #fe841b;
}

.available {
  background-color: #eef6fe;
  color: #269aff;
}

.pb-130px {
  padding-bottom: 130px;
}

.text-name-table {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
}

.border-1px-FF7B2C {
  border: 1px solid #ff7b2c;
}

.w-125px {
  width: 125px;
}

.cusrsor-move {
  cursor: move;
}

.border-12px {
  border-radius: 12px;
}

.border-circle {
  border-radius: 50%;
}
