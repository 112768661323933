.modal-add-food-order .modal-lg {
  max-width: 60% !important;
}

.modal-add-food-order .modal-dialog.modal-dialog-centered .modal-content {
  width: 100% !important;
  border-radius: 20px;
}

.object-fit-fill {
  object-fit: fill;
}

.name-food-add-to-pos-menu {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
}

.object-fit-cover {
  object-fit: cover;
}

.height-170px {
  height: 170px;
}

.text-price-modal-food-pos-menu {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.text-modal-dollar-pos-menu {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff7b2c;
}

.btn-add-card-modal {
  width: 100%;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  background-color: #0b2f88;
  height: 42px;
}
.btn-togo-add-food {
  padding: 0px 12px;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a6a;
  background-color: white;
  height: 42px;
  border: 1px solid #999999;
}
.btn-togo-add-food-2 {
  padding: 0px 12px;
  width: fit-content;
  border-radius: 8px;
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  background-color: #ff7b2c;
  height: 42px;
}

.text-choose-status-order-modal {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  color: #4a4a6a;
}

.border-FF7B2C {
  border: 1px solid #ff7b2c;
}

.bg-FF7B2C {
  background-color: #ff7b2c;
}

.box-img-add-food-owner {
  width: 250px;
  height: 150px;
}

.add-custom-food {
  width: 280px;
  position: absolute;
  right: 30px;
  top: 0;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #999999;
}
