@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Madimi+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Madimi+One&family=Rampart+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Madimi+One&family=Ramabhadra&family=Rampart+One&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans+Collegiate+One:ital@0;1&family=Madimi+One&family=Oleo+Script:wght@400;700&family=Ramabhadra&family=Rampart+One&display=swap");

.gift-card-printer {
  width: 400px;
  height: 200px;
}
.gift-card-value {
  font-family: "Madimi One", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  color: white;
  line-height: normal;
  margin: 0;
}
.gift-card-title {
  font-family: "Madimi One", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: white;
  line-height: normal;
  margin: 0;
}

.gift-card-content {
  font-family: "Rampart One", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
  color: white;
  line-height: normal;
  margin: 0;
}

.gift-card-name-store {
  color: white;
  font-family: "Oleo Script", system-ui !important;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  text-align: center;
  line-height: normal;
}
.gift-card-store-info {
  color: rgb(194, 194, 194);
  font-family: "Roboto", sans-serif !important;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  /* padding: 0px 15px 0px 15px; */
}
.store-info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  max-height: 48px; /* fallback */
}
.gift-card-expire-day {
  margin-top: 4px;
  font-weight: 400;
  font-family: "Oleo Script", system-ui !important;
  font-style: normal;
  font-size: 14px;
  color: white;
  text-align: center;
  font-style: italic;
}

.box-gift-card-printer {
  position: fixed;
  top: -1000000px;
  right: -10000px;
}
