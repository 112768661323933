.modal-delete-customer.modal-clock-in-out.Modal-sumary .modal-dialog.modal-dialog-centered {
    max-width: 806px;
    width: 806px;
}

.modal-delete-customer.modal-clock-in-out.Modal-sumary .modal-dialog.modal-dialog-centered .modal-dialog {
    max-width: 806px;
    width: 806px;
}

.w-70 {
    width: 70%;
}

.w-50 {
    width: 50%;
}

.w-20 {
    width: 20%;
}

.text-title-sumary {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #666687;
}

.text-value-sumary {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #4A4A6A;
}

.rounded-18px {
    border-radius: 18px;
}

.w-22 {
    width: 22%;
}

.w-12 {
    width: 12%;
}

.text-print-sumary {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    color: #FF7B2C;
}

.bg-FFF2EA {
    background-color: #FFF2EA;
}

.border-FF7B2C {
    border: 1px solid #FF7B2C;
}

tbody .clock-in-clock-out-print {
    display: table-caption;
    padding-top: 15px;
}