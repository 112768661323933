.modal.right-remove-bill .modal-dialog {
  position: fixed;
  margin: auto;
  width: 90%;
  max-width: none;
  /* height: 100vh; */
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right-remove-bill .modal-content {
  height: 950vh;
  overflow-y: auto;
}

.modal.right-remove-bill .modal-body {
  padding: 0;
}

/*Right*/
.modal.right-remove-bill.fade .modal-dialog {
  right: -880px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right-remove-bill.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}
.remove-bill-title {
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #212134;
}
.box-remove-bill {
  height: calc(100vh - 100px);
  overflow: scroll;
  
}
.remove-bill-table {
  background-color: #8acafc;
  border-radius: 8px;
  height: 100px;
  border: 1px solid #005a9f;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-bill-table-2 {
  background-color: #f7bd8e;
  border-radius: 8px;
  height: 100px;
  border: 1px solid #f07c1d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.name-table-remove {
  height: 80%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #005a9f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: white;
}
.name-table-remove-2 {
  height: 80%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-color: #f07c1d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: white;
}

.box-shadow-table{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}