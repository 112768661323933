.modal-add-printer .modal-dialog .modal-content {
  border-radius: 22px;
}

.box-input-add-printer-name {
  border: 1px solid rgb(193, 193, 194);
  border-radius: 12px;
  overflow: hidden;
}

.h-300px {
  height: 300px;
}

.input-enter-printer-name::placeholder {}