.review-restaurant-page {
  padding: 24px 24px 94px 24px;
  background-color: #3460cb;
  min-height: 100%;
}
.review-introduce {
  margin-top: 12px;
  min-height: 400px;
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.review-restaurant-name {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  margin: 0px;
  color: black;
}

.review-introduce-customer {
  padding: 12px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0px;
  color: #424242;
}
.note-feedback-review {
  width: 100%;
  height: 150px;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  border: 1px solid #dcdce4;
  gap: 10px;
  color: #424242;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}
.note-feedback-food {
  width: 100%;
  height: 100px;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
  border: 1px solid #dcdce4;
  gap: 10px;
  color: #424242;
  font-weight: 500;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.note-feedback-review::placeholder {
  font-size: 16px;
  color: #888888;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.btn-submit-review {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background: #3460cb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  color: white;
}
.footer-review {
  height: 70px;
  padding: 15px 40px 15px 40px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-top: 1px solid #999999;
}
.box-note-review {
  padding: 12px;
  border-radius: 12px;
  background-color: rgb(251, 242, 173);
}
.title-note-review-restaurant {
  font-weight: 700;
  color: rgb(240, 101, 26);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin: 0px;
}

.content-note-review-restaurant {
  font-weight: 500;
  color: rgb(251, 156, 13);
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin: 8px 0px 0px 0px;
  padding: 0px 12px;
}
.btn-review-staff {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  margin-top: 12px;
}
button.btn-review-staff:nth-child(even) {
  background-color: #3460cb;
  color: white;
}
button.btn-review-staff:nth-child(odd) {
  background-color: red;
  color: white;
}
button.btn-review-staff:nth-last-child(3) {
  background-color: rgb(243, 196, 42);
  color: white;
}
